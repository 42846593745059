import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { APIS, getApi } from '../../../utils/apiUtils';
import {
  getVehicle,
  addVehicle,
  addBooking,
  addJobs,
  getRepairItem,
  getServiceEstimation,
  updateVehicleQuarry, getAllBookingsForMonth, addBookingParts,
} from '../../../graphql-queries/booking';
import { EMAIL_TYPE } from '../../../enum/ConfigurationData';
import { searchItem } from '../../../graphql-queries/inventory';

export const findDescriptions = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(getRepairItem, { workShopId: `${workShopId}`, labourRateType: `${keyword}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getVehicleDetails = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(getVehicle, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateVehicleDetails = async (updateVehicle) => {
  try {
    return await API.graphql(graphqlOperation(updateVehicleQuarry, { updateVehicle }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllBookings = async (workShopId: string, date1: any, date2: any) => {
  try {
    return await API.graphql(graphqlOperation(getAllBookingsForMonth, { workShopId: `${workShopId}`, date1: `${date1}`, date2: `${date2}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const addVehicleDetails = async (newVehicle) => {
  message.loading('Saving. Please wait', 1);
  try {
    return await API.graphql(graphqlOperation(addVehicle, { newVehicle }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const addBookingDetails = async (newBooking) => {
  try {
    return await API.graphql(graphqlOperation(addBooking, { newBooking }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const addJobList = async (checkInJobs) => {
  try {
    return await API.graphql(graphqlOperation(addJobs, { checkInJobs }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getServiceJobEstimation = async (workShopId, applicableVehicleMake,
  applicableVehicleModel) => {
  try {
    return await API.graphql(graphqlOperation(getServiceEstimation, { workShopId: `${workShopId}`, applicableVehicleMake: `${applicableVehicleMake}`, applicableVehicleModel: `${applicableVehicleModel}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const sendEmailNotification = async (bookingId: string) => {
  try {
    await getApi(APIS.EMAIL_API, `send-email?emailType=${EMAIL_TYPE.BOOKING_SUBMITTED}&bookingId=${bookingId}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
  }
};

export const uploadVehicleContent = async (key, method, contentType = undefined) => {
  try {
    const contentTypeP: string = contentType ? `&contentType=${contentType}` : '';
    return await getApi(APIS.VEHICLE_API, `signed-url?key=${key}&method=${method}${contentTypeP}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const searchInventory = async (workShopId, keyword) => {
  try {
    const res: any = await API.graphql(graphqlOperation(searchItem, { workShopId: `${workShopId}`, searching: `${keyword}` }));
    return res?.data?.searchItem || [];
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const saveBookingParts = async (parts) => {
  try {
    return await API.graphql(graphqlOperation(addBookingParts, { newBookingParts: parts }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
