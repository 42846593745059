/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import './AppointmentsPage.scss';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Col,
  Row,
  TimePicker,
  Typography,
  Table,
  AutoComplete, message, Calendar, Drawer, Badge, Popover, Tooltip,
} from 'antd';
import moment from 'moment';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ulid } from 'ulid';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addBookingDetails,
  findDescriptions,
  getVehicleDetails, addJobList,
  getServiceJobEstimation, sendEmailNotification, getAllBookings, updateVehicleDetails, saveBookingParts, searchInventory,
} from './actions';
import OwnerDetails from './Components/OwnerDetails';
import AddNewVehicle from './Components/AddNewVehicle';
import { AddBookingTour, MakeAnAppoinmentTour } from './AddBookingTour';
import { getColourByBookingType } from '../../../utils/commonUtils';
import { BOOKING_TYPES } from '../../../enum/BookingStatus';
import { JOB_TYPES } from '../../../enum/ConfigurationData';

const PARTS = 'Spare Parts';

export const AppointmentsPage = ({ workShopId, configData }: any) => {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [jobTypeOptions, setJobTypeOptions] = useState<any[]>([]);
  const [repairItemsOptions, setRepairItemsOptions] = useState<any[]>([]);
  const [value, setValue] = useState(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [descriptions, setDescriptions] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState(moment());

  const [plateNumberList, setPlateNumberList] = useState<any[]>([]);
  const [isFieldDisabled, setIsFieldDisabled] = useState<boolean>(true);
  const [isDescription, setIsDescription] = useState<boolean>(false);
  const [isRateShow, setIsRateShow] = useState<boolean>(false);
  const [selectedPlate, setSelectedPlate] = useState<any>();
  const [vehiclePlates, setVehiclePlates] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [selectedRepairItem, setSelectedRepairItem] = useState();
  const [currency, setCurrency] = useState<any>();
  const [quickjob, setQuickjob] = useState<boolean>(false);
  const [isService, setIsService] = useState<boolean>(false);
  const [inventoryDatArray, setInventoryDataArray] = useState<any[]>([]);
  const [inventoryParts, setInventoryParts] = useState<any[]>([]);

  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);
  const [calenderData, setCalenderData] = useState<any[]>([]);

  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setVehiclePlates([]);
    setTableData([]);
    setPlateNumberList([]);
    setFormattedTableData([]);
    setIsRateShow(false);
    setIsDescription(false);
    setIsFieldDisabled(true);
    setSelectedPlate(undefined);
    setTotalPrice(0);
    setTotal(0);
  };

  useEffect(() => {
    if (value) {
      setSelectedTime(value);
    }
  }, [value]);

  const onChange = (time) => {
    setValue(time);
    setSelectedTime(time);
  };

  const getAllBookingsForMonth = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    getAllBookings(workShopId, startOfMonth, endOfMonth).then((res: any) => {
      const bookingsForMonth = res.data.getAllBookingsForMonth;
      setCalenderData(bookingsForMonth);
    }).catch((e: any) => {
      console.log(e);
      message.error('Something went wrong');
    });
  };

  const sortJobs = (array) => array.sort((a, b) => {
    const nameA = a.repairItem?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.repairItem?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const timeConvert = (num) => {
    const hours = (num / 60);
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.floor(Math.round(minutes));
    if (rHours > 0) {
      if (rMinutes > 0) return `${rHours} hr(s) ${rMinutes} min(s)`;
      return `${rHours} hr(s)`;
    }
    return `${rMinutes} min(s)`;
  };

  const getDescriptions = async (selectedItem) => {
    if (form.getFieldValue('description')) {
      form.resetFields(['description', 'rate', 'stdHrs', 'quantity']);
    }
    if (selectedItem !== selectedRepairItem) {
      setSelectedRepairItem(selectedItem);
    }
    const res: any = await findDescriptions(workShopId, selectedItem);
    const descriptionList: any[] = res?.data?.getRepairItem || [];
    const suggestionArray: any[] = descriptionList.map(({ description }) => ({ value: description }));
    setSuggestions(suggestionArray);
    setDescriptions(descriptionList);
  };

  const addOrRemoveTitlesToTableData = (tdData) => {
    let jobsList = tdData.filter((tdItem) => !tdItem.title);
    const tempArray: any[] = [];
    let itemType = '';
    jobsList = sortJobs(jobsList);
    jobsList.forEach((item: any) => {
      if (itemType === item.repairItem) {
        tempArray.push(item);
      } else {
        itemType = item.repairItem;
        tempArray.push({ title: itemType });
        tempArray.push(item);
      }
    });
    const formattedTempArray: any[] = [];
    tempArray.forEach((val: any) => {
      const obj = JSON.parse(JSON.stringify(val));
      // eslint-disable-next-line no-restricted-globals
      if (val.rate && !isNaN(obj.rate)) obj.rate = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(val.rate);
      // eslint-disable-next-line no-restricted-globals
      if (val.stdHrsMin && !isNaN(val.stdHrsMin)) obj.stdHrsMin = timeConvert(val.stdHrsMin);
      formattedTempArray.push(obj);
    });
    setTableData(tempArray);
    setFormattedTableData(formattedTempArray);
  };

  const id = ulid();

  const isQuickJob = JOB_TYPES[JOB_TYPES.QuickJobs].toString().toLowerCase();

  const addPartsList = async (bookingId: string, partsList: any[]) => {
    const parts = partsList.map((part) => ({
      workShopId,
      bookingId,
      description: part.description,
      partId: part.sortKey,
      partNumber: part.partNumber,
      price: part.price,
      quantity: part.qtyNo,
      image: part.image,
    }));
    await saveBookingParts(parts);
  };

  // eslint-disable-next-line consistent-return
  const createBooking = async () => {
    const {
      plateNumber,
      jobType,
      selectedDate,
      currentMileage,
    } = form.getFieldsValue();

    if (!plateNumber || plateNumber === '') {
      return message.error('Please enter the plate number.');
    }
    if (!jobType || jobType === '') {
      return message.error('Please select the job type.');
    }
    if (!selectedTime) {
      return message.error("Selected Time can't be empty");
    }
    if (!currentMileage) {
      return message.error('Please enter mileage.');
    }

    const newJobs: any[] = [];
    const newPars: any[] = [];
    const tdData: any[] = [];

    const {
      batteryType,
      batteryReplacementDate,
      chassisNumber,
      colourCode,
      engineNumber,
      fuelType,
      insuranceNumber,
      insuranceRenevalDate,
      insuranceCompany,
      licenseNumber,
      licenseRenevalDate,
      ownerName,
      ownerTel,
      ownerEmail,
      userName,
      userTel,
      userEmail,
      tireSize,
      make,
      manufactureYear,
      model,
      registerYear,
      mileage,
    } = selectedPlate;

    let bookingStdHrsMin = 0;

    tableData.filter((item: any) => !item.title).forEach((job: any) => {
      if (job.repairItem === PARTS) {
        newPars.push(job);
      } else {
        tdData.push(job);
        bookingStdHrsMin += parseFloat(job.stdHrsMin) * parseFloat(job.qtyNo);
      }
    });

    const newBooking: any = {
      batteryType,
      batteryReplacementDate,
      chassisNumber,
      colourCode,
      engineNumber,
      fuelType,
      insuranceNumber,
      insuranceCompany,
      insuranceRenevalDate,
      licenseNumber,
      licenseRenevalDate,
      ownerName,
      ownerTel,
      ownerEmail,
      userName,
      userTel,
      userEmail,
      tireSize,
      make,
      manufactureYear,
      model,
      registerYear,
      mileage: currentMileage || mileage,
      selectedTime,
      selectedDate,
      workShopId,
      sortKey: id,
      jobType,
      status: jobType === isQuickJob ? BOOKING_TYPES.TO_DO : BOOKING_TYPES.CHECKIN_TO_DO,
      progress: 0,
      plateNumber,
      totalPrice,
      total,
      bookingStdHrsMin,
      assigneeId: 'undefiend',
      indexSortKey: `${workShopId}_${ulid()}`,
      updatedTime: new Date(),
      createdTime: new Date(),
    };

    delete newBooking.vehicleId;

    tdData.map((job: any) => {
      // @ts-ignore
      const obj = {
        bookingId: id,
        sortKey: job.dataId,
        description: job.description,
        price: parseFloat(job.price),
        rate: parseFloat(job.rate),
        qtyHours: job.stdHrsMin,
        qtyNo: parseInt(job.qtyNo, 10),
        plateNumber,
        status: jobType === isQuickJob ? BOOKING_TYPES.TO_DO : BOOKING_TYPES.CHECKIN_TO_DO,
        assigneeId: 'undefiend',
        invoiceId: undefined,
        repairItem: job.repairItem,
        indexSortKey: `${workShopId}_${ulid()}`,
        updatedTime: new Date(),
        createdTime: new Date(),
      };
      // @ts-ignore
      return newJobs.push(obj);
    });

    const obj = { ...selectedPlate, mileage: currentMileage };
    await addBookingDetails(newBooking).then(async (res) => {
      message.loading('Booking Saving');
      if (!res) {
        message.destroy();
        message.error('Error');
      } else {
        if (newPars.length) {
          await addPartsList(id, newPars);
        }

        if (newJobs.length) {
          await addJobList(newJobs).then(async (result) => {
            if (!result) {
              message.destroy();
              message.error('Error');
            } else {
              await sendEmailNotification(id);
            }
          });
        }
        await updateVehicleDetails(obj);
        handleCancel();
        message.destroy();
        message.success('Booking Added Successfully');
      }
    });

    getAllBookingsForMonth();
  };

  const deleteFromTable = (dataId: any) => {
    const newTableData = tableData.filter((item: any) => item.dataId !== dataId);
    addOrRemoveTitlesToTableData(newTableData);
  };

  const calculateTotal = () => {
    let newTotal = 0;
    const tdData = tableData.filter((item: any) => !item.title);
    // eslint-disable-next-line no-return-assign
    tdData.map((item: any) => {
      const itemPrice = parseFloat(item.price);
      newTotal += itemPrice;
      return newTotal;
    });
    setTotalPrice(newTotal);
    setTotal(newTotal);
  };

  const sharedOnCell = (txt, item) => ({
    props: {
      colSpan: item.title ? 0 : 1,
    },
    children: <div>{txt}</div>,
  });

  const columns = [
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      key: 'description',
      width: '380px',
      render: (title, item) => ({
        props: {
          style: item.title && {
            background: 'rgba(135,130,254,0.25)',
            fontWeight: 'bold',
            borderRadius: '4px',
          },
          colSpan: item.title ? 6 : 1,
          rowSpan: 1,
        },
        children: <div>{item.description || item.title}</div>,
      }),
    },
    {
      title: `${t('stdHours')}`,
      dataIndex: 'stdHrsMin',
      key: 'stdHrsMin',
      width: '150px',
      render: sharedOnCell,
    },
    {
      title: `${t('rate')}`,
      dataIndex: 'rate',
      key: 'rate',
      width: '120px',
      render: sharedOnCell,
    },
    {
      title: `${t('Quantity')}`,
      dataIndex: 'qtyNo',
      key: 'qtyNo',
      width: '110px',
      render: sharedOnCell,
    },
    {
      title: `${t('price')}`,
      dataIndex: 'price',
      key: 'price',
      width: '110px',
      render: (item, obj) => ({
        props: {
          colSpan: obj.title ? 0 : 1,
        },
        children: (<span>{new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format((parseFloat(obj.price) + (parseFloat(obj.vat) || 0) + (parseFloat(obj.nbt) || 0) + (parseFloat(obj.otherVat) || 0)))}</span>),
      }),
    },
    {
      dataIndex: 'delete',
      key: 'delete',
      width: '70px',
      render: (item, obj) => ({
        props: {
          colSpan: obj.title ? 0 : 1,
        },
        children: (
          <>
            <DeleteOutlined onClick={() => deleteFromTable(obj.dataId)} />
          </>
        ),
      }),
    },
  ];

  // eslint-disable-next-line consistent-return
  const addTableValues = (inventoryData = undefined) => {
    if (inventoryData) {
      form.resetFields(['inventoryPartPrice', 'inventoryPartQuantity', 'inventoryPart']);
      const tdData = [...tableData, inventoryData];
      addOrRemoveTitlesToTableData(tdData);
      return message.success('Part added successfully');
    }
    const {
      stdHrs, rate, description, quantity,
    } = form.getFieldsValue();
    let qty = 1;
    const standardTimeMin: number = parseInt(stdHrs.value, 10) * stdHrs.timeUnit;

    if (!description || description === '') {
      return message.error('Please add the job description.');
    }

    if (quantity < 0) {
      return message.error('Invalid Quantity Value');
    }

    if (quantity) {
      qty = quantity;
    }
    // eslint-disable-next-line max-len
    const descriptionObj: any[] = descriptions.filter((item: any) => item.description.toUpperCase() === description.toUpperCase());
    const rateVal: number = descriptionObj[0] ? parseFloat(descriptionObj[0].labourRate) : parseFloat(rate);
    const stdHrsMin: number = descriptionObj[0] ? Math.round(parseFloat(descriptionObj[0].stdHrsMin)) : standardTimeMin;
    const tempPrice: number = (rateVal * (stdHrsMin / 60)) * qty;
    const price = Number((tempPrice)).toFixed(2);

    if (stdHrsMin < 0 || !stdHrsMin) {
      return message.error('Std Hrs not Available');
    }

    if (rateVal < 0 || !rateVal) {
      return message.error('Rate Value not Available');
    }

    const newData = {
      dataId: ulid(),
      description,
      rate: rateVal,
      stdHrsMin,
      qtyNo: qty,
      price,
      repairItem: selectedRepairItem,
    };

    form.resetFields(['description', 'rate', 'stdHrs', 'quantity']);

    const tdData = [...tableData, newData];
    addOrRemoveTitlesToTableData(tdData);
    message.success('Repair item added successfully');
  };

  const onSelect = (v) => {
    form.setFieldsValue({ selectedDate: moment(v, 'YYYY-MM-DD') });
    showModal();
    setSelectedValue(v);
  };

  const vehicleSelected = (e: any) => {
    // eslint-disable-next-line no-shadow
    const selectedPlate = vehiclePlates.filter((item: any) => item.plateNumber === e);
    setSelectedPlate(selectedPlate[0]);
    setIsFieldDisabled(false);
  };

  const vehicleNotSelected = (keyword: String) => {
    if (keyword !== '') {
      getVehicleDetails(workShopId, keyword.toLowerCase()).then((res: any) => {
        const array = [];
        // @ts-ignore
        res.data.getVehicle.map((item) => array.push({ value: item.plateNumber }));
        setVehiclePlates(res.data.getVehicle);
        setPlateNumberList(array);
      });
    } else {
      setPlateNumberList([]);
    }
    setIsFieldDisabled(true);
  };

  const selectedDescription = (e) => {
    const {
      rate, stdHrs,
    } = form.getFieldsValue();
    const standardTimeMin: number = parseInt(stdHrs.value, 10) * stdHrs.timeUnit;
    setIsDescription(false);
    const [selectedDes] = descriptions
      .filter(({ description } : any) => description.toUpperCase() === e.toUpperCase());
    const rateVal: number = selectedDes ? parseFloat(selectedDes.labourRate) : parseFloat(rate);
    const stdHrsMin: number = selectedDes ? Math.round(parseFloat(selectedDes.stdHrsMin)) : standardTimeMin;
    form.setFieldsValue({ rate: rateVal, stdHrs: { value: stdHrsMin, timeUnit: 1 } });
  };

  const onSelectJob = async (e) => {
    form.resetFields(['selectItem', 'description', 'rate', 'stdHrs', 'quantity']);
    setQuickjob(false);
    if (e === isQuickJob) {
      form.setFieldsValue({ selectItem: repairItemsOptions.filter((item: any) => item.value === e)[0] });
      setQuickjob(true);
      setSelectedRepairItem(e);
    }
    setIsRateShow(true);
    setIsService(false);
    if (e === 'service') {
      form.setFieldsValue({ selectItem: repairItemsOptions.filter((item: any) => item.value === e)[0] });
      setSelectedRepairItem(e);
      setIsService(true);
      const serviceJobs = [];
      const qty = '1';
      let priceTot = 0;
      const { make } = vehiclePlates[0];
      const { model } = vehiclePlates[0];
      await getServiceJobEstimation(workShopId, make, model).then(async (res) => {
        if (!res) {
          message.error('Error');
        } else {
          res.data.getServiceEstimation.map((job: any) => {
            const rateVal = job.labourRate;
            const { description } = job;
            const { stdHrsMin } = job;
            const { price } = job;
            const newData = {
              description,
              rate: rateVal,
              stdHrsMin,
              qtyNo: qty,
              price,
            };
            serviceJobs.push(newData);
            priceTot += parseFloat(price);
            return null;
          });
          setTableData(serviceJobs);
          setTotalPrice(priceTot);
          setTotal(priceTot);
        }
      });
    }
    const res: any = await findDescriptions(workShopId, e);
    const descriptionList: any[] = res?.data?.getRepairItem || [];
    const suggestionArray: any[] = descriptionList.map(({ description }) => ({ value: description }));
    setSuggestions(suggestionArray);
    setDescriptions(descriptionList);
  };

  const getDisabledHours = () => {
    if (moment().format('YYYY-MM-DD') === selectedValue.format('YYYY-MM-DD')) {
      const hours: any[] = [];
      for (let i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
      return hours;
    }
    return null;
  };

  const getDisabledMinutes = (selectedHour) => {
    if (moment().format('YYYY-MM-DD') === selectedValue.format('YYYY-MM-DD')) {
      const minutes: any[] = [];
      if (selectedHour === moment().hour()) {
        for (let i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
      return minutes;
    }
    return null;
  };

  const getDisabledSeconds = (selectedMinutes) => {
    if (moment().format('YYYY-MM-DD') === selectedValue.format('YYYY-MM-DD')) {
      const seconds: any[] = [];
      if (selectedMinutes === moment().hour()) {
        for (let i = 0; i < moment().second(); i++) {
          seconds.push(i);
        }
      }
      return seconds;
    }
    return null;
  };

  useEffect(() => {
    calculateTotal();
  }, [tableData]);

  useEffect(() => {
    if (configData) {
      setJobTypeOptions(configData?.jobTypes);
      setRepairItemsOptions(configData?.repairItems);
      setCurrency(configData?.currency);
    }
  }, [configData]);

  // eslint-disable-next-line consistent-return
  const disabledDate = (current) => {
    if (current) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return current.valueOf() < today;
    }
  };

  const dateCellRender = (v) => {
    const bookingForSelectedDate = calenderData.filter((item: any) => {
      const sDate = moment(item.selectedDate);
      return sDate.date() === v.date() && sDate.month() === v.month() && sDate.year() === v.year();
    });
    const jobType: string[] = [];
    bookingForSelectedDate.forEach((booking: any) => {
      const jType: string = booking.jobType;
      if (jType && !jobType.includes(jType)) {
        jobType.push(jType);
      }
    });

    const jobData: any[] = [];
    jobType.forEach((job: any) => {
      const bookings = bookingForSelectedDate.filter((booking: any) => booking.jobType === job);
      const content = `${job.charAt(0).toUpperCase() + job.slice(1)}: ${bookings.length}`;
      const colour = getColourByBookingType(job);
      jobData.push({
        jobType: job, content, colour, bookings,
      });
    });

    if (jobData.length) {
      return (
        <div style={{ marginTop: 20 }}>
          <Row>
            <Badge status="processing" color="#2F80ED" text={`Total: ${bookingForSelectedDate.length}`} />
          </Row>
          {jobData.map((item: any) => (
            <Row key={item.content}>
              <Badge status="processing" color={item.colour} text={item.content} />
            </Row>
          ))}
        </div>
      );
    }
    return <div />;
  };

  const addInventoryPart = () => {
    const { inventoryPart, inventoryPartPrice, inventoryPartQuantity = 1 } = form.getFieldsValue();
    if (!inventoryPart || !inventoryPartPrice) {
      return message.error('Invalid Input');
    }
    let [inventoryData] = inventoryDatArray.filter(({ sortKey }: any) => sortKey === inventoryPart);
    const goodIn = JSON.parse(inventoryData?.goodsIn);
    if (parseInt(goodIn.value, 10) < inventoryPartQuantity || parseInt(goodIn.value, 10) === 0) {
      return message.error('Not enough spare parts');
    }
    inventoryData = {
      ...inventoryData, price: inventoryPartPrice * inventoryPartQuantity, qtyNo: inventoryPartQuantity, repairItem: PARTS,
    };
    addTableValues(inventoryData);
    return null;
  };

  const getInventoryParts = async (keyword: string) => {
    const searchResults = await searchInventory(workShopId, keyword);
    setInventoryDataArray(searchResults);
    setInventoryParts(searchResults.map(({ description, sortKey }) => ({ value: sortKey, label: description })));
  };

  useEffect(() => {
    if (workShopId) {
      getAllBookingsForMonth();
    }
  }, [workShopId]);

  return (
    <>
      <div>
        <AddBookingTour />
        <Title level={3} className="ks_lbl_titl">{t('Make an Appointment')}</Title>
        <div style={{ padding: '0px 100px 0px 100px' }}>
          <Calendar
            dateCellRender={!!calenderData.length && dateCellRender}
            className="calender"
            value={selectedValue}
            onSelect={onSelect}
            disabledDate={disabledDate}
            validRange={[moment().subtract(1, 'days'), moment().add(10, 'years')]}
          />
        </div>
      </div>
      <Drawer
        title={(
          <Row>
            <Col span={16}>
              {t('Make an Appointment')}
            </Col>
          </Row>
        )}
        placement="right"
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        disabledDate={(cd) => cd.isBefore(moment())}
        onOk={createBooking}
        onClose={handleCancel}
        okText="Submit"
        visible={isModalVisible}
      >
        <MakeAnAppoinmentTour />
        <Form form={form} layout="vertical" requiredMark={false} onFinish={createBooking}>
          <Row className="first-row">
            <Col xs={8} sm={8} md={7} lg={8} xl={8} offset={1}>
              <Form.Item
                label={t('Plate Number')}
                className="ka_lbl_bkfrm plateNumber-input"
                name="plateNumber"
                id="plateNumber"
                rules={[() => ({
                  validator(rule, value) {
                    if (plateNumberList.some((item) => item.value === value)) return Promise.resolve();
                    if (value) { if (!plateNumberList.some((item) => item.value === value)) return Promise.reject(new Error('Your vehicle is not registered')); }

                    return Promise.reject(new Error('Please enter the vehicle plate number'));
                  },
                })]}
              >
                <AutoComplete
                  className="ks_drp_vcl-ty selection_syl"
                  options={plateNumberList}
                  onSelect={vehicleSelected}
                  onChange={vehicleNotSelected}
                  placeholder="eg : CAG-7823"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={5} lg={6} xl={6} offset={1}>
              <Form.Item
                label={t('Job Type')}
                className="ka_lbl_bkfrm"
                name="jobType"
                id="jobType"
                rules={[{
                  required: true,
                  message: `${t('Please select Job type')}`,
                }]}
              >
                <Select
                  className="ks_drp_vcl-ty selection_syl"
                  placeholder={t('Select a Job Type')}
                  options={jobTypeOptions}
                  disabled={isFieldDisabled}
                  onSelect={onSelectJob}
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} offset={1}>
              <Form.Item
                name="currentMileage"
                label={t('Mileage')}
                dependencies={[selectedPlate?.mileage]}
                rules={[{
                  required: true,
                  message: `${t('Please enter mileage')}`,
                }, {
                  pattern: /^\d+$/,
                  message: 'Please enter a valid mileage ( mileage must be a number )',
                },
                () => ({
                  validator(rule, value) {
                    if (selectedPlate?.mileage && value && value < selectedPlate.mileage) return Promise.reject(new Error('Booking mileage cannot be less than your vehicle mileage'));

                    return Promise.resolve();
                  },
                }),

                ]}
              >
                <Input placeholder={t('Enter Mileage')} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="second-row">
            <Col xs={8} sm={8} md={7} lg={8} xl={8} offset={1}>
              <Form.Item label={t('date')} className="ka_lbl_bkfrm" name="selectedDate" id="selectedDate" rules={[{ required: true, message: `${t('Please select a Booking Date')}` }]}>
                <DatePicker
                  disabledDate={(cd) => cd.isBefore(moment())}
                  className="ks_drp_vcl-ty selection_syl"
                  format="DD-MM-YYYY"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={5} lg={6} xl={6} offset={1}>
              <Form.Item label={t('time')} className="ka_lbl_bkfrm" name="selectTime" id="selectedTime" rules={[{ required: true, message: `${t('Please select a Booking Time')}` }]}>
                <TimePicker
                  value={value}
                  placeholder={t('Select time')}
                  onChange={onChange}
                  className="ks_drp_vcl-ty selection_syl"
                  disabled={isFieldDisabled}
                  defaultValue={moment()}
                  disabledHours={getDisabledHours}
                  disabledMinutes={getDisabledMinutes}
                  disabledSeconds={getDisabledSeconds}
                  use12Hours
                  format="h:mm A"
                />
              </Form.Item>
            </Col>
            <Col style={{ paddingTop: '2rem' }} xs={5} sm={5} md={7} lg={5} xl={5} offset={2}>
              {isFieldDisabled ? (
                <div>
                  <span style={{ marginRight: 17 }} className="ka_lbl_bkfrm">{t('Add New Vehicle')}</span>
                  <AddNewVehicle />
                </div>
              )
                : (
                  <div>
                    <span style={{ marginRight: 17 }} className="ka_lbl_bkfrm">{t('More Information')}</span>
                    <OwnerDetails details={selectedPlate} />
                  </div>
                )}
            </Col>
          </Row>
          {isRateShow
            && (
              <>
                <Row style={{ marginBottom: '-20px' }}>
                  <Col xs={4} sm={4} md={3} lg={4} xl={4} offset={1}>
                    <Form.Item
                      label={t('Repair Items')}
                      className="ka_lbl_bkfrm"
                      name="selectItem"
                      id="repairItems"
                      rules={[{
                        required: true,
                        message: 'Please select an Item',
                      }]}
                    >
                      <Select
                        className="ks_drp_vcl-ty selection_syl"
                        placeholder={t('Repair Item')}
                        options={repairItemsOptions}
                        onChange={getDescriptions}
                        disabled={isFieldDisabled || quickjob || isService}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={5} sm={5} md={4} lg={5} xl={5} offset={1}>
                    <Tooltip title={form?.getFieldValue('description')} arrowPointAtCenter>
                      <Form.Item
                        label={t('Description')}
                        className="ka_lbl_bkfrm"
                        id="description"
                        name="description"
                      >
                        <AutoComplete
                          className="ks_drp_vcl-ty selection_syl"
                          options={suggestions}
                          placeholder={t('Type/Select a Description')}
                          disabled={isFieldDisabled}
                          onSelect={selectedDescription}
                          onChange={() => setIsDescription(true)}
                          filterOption={(inputValue: string, option: any) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col span={4} offset={1}>
                    <Form.Item label={t('Standard Time')}>
                      <Input.Group compact style={{ marginTop: '2px' }}>
                        <Form.Item
                          name={['stdHrs', 'value']}
                          style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                          rules={[{ required: true, message: 'Please enter time value' }]}
                        >
                          <Input type="number" min={1} className="ks_int_bk ks_int_bkfrm" placeholder={t('stdHours')} disabled={isFieldDisabled || !isDescription} />
                        </Form.Item>
                        <Form.Item
                          name={['stdHrs', 'timeUnit']}
                          style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                          rules={[{ required: true, message: 'Please select time unit' }]}
                        >
                          <Select
                            className="ks_int_bk ks_int_bkfrm"
                            placeholder="Unit.."
                            size="middle"
                            disabled={isFieldDisabled || !isDescription}
                          >
                            <Select.Option value={1}>min</Select.Option>
                            <Select.Option value={60}>hours</Select.Option>
                          </Select>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={1}>
                    <Form.Item label={t('rate')} className="ka_lbl_bkfrm" name="rate" id="rate" rules={[{ required: true, message: 'Please input rate' }]}>
                      <Input className="ks_int_bk ks_int_bkfrm" type="number" placeholder={t('rate')} disabled={isFieldDisabled || !isDescription} />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1}>
                    <Form.Item label={t('Quantity')} className="ka_lbl_bkfrm" name="quantity" id="quantity">
                      <Input className="ks_int_bk ks_int_bkfrm" type="number" placeholder="quantity" min={1} defaultValue="1" />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1} style={{ textAlign: 'left', paddingTop: '2rem' }}>
                    {!isFieldDisabled
                    && (
                      <Popover placement="topLeft" content={t('Add Item')} trigger="hover">
                        <Button
                          onClick={() => addTableValues()}
                          style={{ backgroundColor: '#131A54', marginLeft: '7px' }}
                          shape="circle"
                          icon={<PlusOutlined style={{ color: 'white' }} />}
                          size="large"
                        />
                      </Popover>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginBottom: '-20px' }}>
                  <Col xs={13} sm={13} md={12} lg={13} xl={13} offset={1}>
                    <Form.Item
                      label={t('Spare Parts')}
                      className="ka_lbl_bkfrm"
                      name="inventoryPart"
                    >
                      <Select
                        showSearch
                        className="ks_drp_vcl-ty selection_syl"
                        placeholder={t('Select Spare Part')}
                        options={inventoryParts}
                        disabled={isFieldDisabled}
                        onSearch={getInventoryParts}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} offset={1}>
                    <Form.Item label={t('Price')} className="ka_lbl_bkfrm" name="inventoryPartPrice" id="inventoryPartPrice" rules={[{ required: true, message: 'Please input rate' }]}>
                      <Input className="ks_int_bk ks_int_bkfrm" type="number" placeholder={t('price')} />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1}>
                    <Form.Item label={t('Quantity')} className="ka_lbl_bkfrm" name="inventoryPartQuantity" id="inventoryPartQuantity">
                      <Input className="ks_int_bk ks_int_bkfrm" type="number" placeholder="quantity" min={1} defaultValue="1" />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1} style={{ textAlign: 'left', paddingTop: '2rem' }}>
                    {!isFieldDisabled
                    && (
                      <Popover placement="topLeft" content={t('Add Item')} trigger="hover">
                        <Button
                          onClick={addInventoryPart}
                          style={{ backgroundColor: '#131A54', marginLeft: '7px' }}
                          shape="circle"
                          icon={<PlusOutlined style={{ color: 'white' }} />}
                          size="large"
                        />
                      </Popover>
                    )}
                  </Col>
                </Row>
              </>
            )}

          <Row style={{ marginTop: '-5px' }}>
            <Title level={3}>{t('Total Estimation')}</Title>
          </Row>

          <Row style={{ marginTop: '15px' }}>
            <Table
              pagination={false}
              dataSource={formattedTableData}
              columns={columns}
              footer={() => (
                <Row>
                  <Col span={19}>
                    <div className="total-column" style={{ paddingRight: 25 }}>{t('Total')}</div>
                  </Col>
                  <Col span={4} className="total" style={{ paddingLeft: 42 }}>
                    {new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(totalPrice)}
                  </Col>
                </Row>
              )}
            />
          </Row>
          <Row className="Button-row" style={{ float: 'right' }}>
            <Button onClick={handleCancel} style={{ borderRadius: '8px', marginRight: 20 }}>{t('Cancel')}</Button>
            <Button type="primary" onClick={createBooking} style={{ borderRadius: '8px', marginRight: 20 }}>
              {t('Submit')}
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(AppointmentsPage);
