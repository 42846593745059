// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_SN = {
  'Make an Appointment': 'වෙන්කිරීමක් කරන්න',
  topic: 'වෙන්කිරීමක් කරන්න',
  'Repair Items': 'අලුත්වැඩියා අයිතම',
  'Select an Item': 'අයිතමයක් තෝරන්න',
  'Unit..': 'ඒකකය..',
  'Type Description': 'Type Description',
  'Please enter vehicle make': 'කරුණාකර වාහන සෑදීම ඇතුළු කරන්න',
  'Please enter insurance details': 'කරුණාකර රක්ෂණ විස්තර ඇතුළත් කරන්න',
  'Please enter vehicle owner name': 'කරුණාකර වාහනයේ හිමිකරුගේ නම ඇතුළත් කරන්න',
  'Please enter contact number with 10 digits': 'කරුණාකර අංක 10 කින් සම්බන්ධතා අංකය ඇතුළත් කරන්න',
  'Please enter contact number': 'කරුණාකර සම්බන්ධතා අංකය ඇතුළු කරන්න',
  'Please enter a valid email address': 'කරණාකර වලංගු ඊතැපැල් ලිපිනයක් ඇතුළු කරන්න',
  'Please enter email': 'කරුණාකර විද්‍යුත් තැපෑල ඇතුළත් කරන්න',
  'Please enter vehicle model': 'කරුණාකර වාහන ආකෘතිය ඇතුළු කරන්න',
  'Please enter year of manufacture': 'කරුණාකර නිෂ්පාදිත වර්ෂය ඇතුළත් කරන්න',
  'Please enter year of register': 'කරුණාකර ලියාපදිංචි වර්ෂය ඇතුළත් කරන්න',
  'Please enter engine number': 'කරුණාකර එන්ජින් අංකය ඇතුලත් කරන්න',
  'Please enter chassis number': 'කරුණාකර චැසි අංකය ඇතුලත් කරන්න',
  'Please enter mileage': 'කරුණාකර සැතපුම් ගණන ඇතුළත් කරන්න',
  'Enter Mileage': 'මයිලේජ් ඇතුල් කරන්න',
  'Please enter color code': 'කරුණාකර වර්ණ කේතය ඇතුලත් කරන්න',
  'Salary Details': 'වැටුප් විස්තර',
  'EPF Employee Contribution': 'EPF සේවක දායකත්වය',
  'ETF Company Contribution': 'ETF සමාගමේ දායකත්වය',
  'EPF Company Contribution': 'EPF සමාගමේ දායකත්වය',
  'Please enter tier size': 'කරුණාකර ස්ථර ප්‍රමාණය ඇතුළු කරන්න',
  'Please enter battery rate': 'කරුණාකර බැටරි අනුපාතය ඇතුළත් කරන්න',
  'Please enter fuel type': 'කරුණාකර ඉන්ධන වර්ගය ඇතුළු කරන්න',
  'Please enter license details': 'කරුණාකර බලපත්‍ර විස්තර ඇතුළත් කරන්න',
  'Plate Number': 'තහඩු අංකය',
  'Job Type': 'සේවා වර්ගය',
  'Select a Job Type': 'සේවා වර්ගයක් තෝරන්න',
  'Vehicle Make': 'වාහන සෑදීම',
  'Enter Vehicle Make': 'වාහන සෑදීම ඇතුළු කරන්න',
  date: 'දිනය',
  time: 'වේලාව',
  SDate: 'දිනයක් තෝරන්න',
  Stime: 'වේලාවක් තෝරන්න',
  'Add New Vehicle': 'නව වාහනයක් එක් කරන්න',
  'More Information': 'වැඩි විස්තර',
  'Repair Item': 'අයිතමය',
  'Please select Job type': 'සේවා වර්ගයක් තෝරන්න',
  'Please select a Booking Date': 'කරුණාකර වෙන් කිරීමේ දිනයක් තෝරන්න',
  'Please select a Booking Time': 'කරුණාකර වෙන් කිරීමේ වේලාවක් තෝරන්න',
  'Select time': 'කාලය තෝරන්න',
  Sitem: 'අයිතමයක් තෝරන්න',
  Description: 'විස්තර',
  rate: 'අගය',
  'Total Estimation': 'සම්පූර්ණ ඇස්තමේන්තුව',
  'Type/Select a Description': 'විස්තරයක් ටයිප් කරන්න/තෝරන්න',
  'Standard Time': 'සම්මත වේලාව',
  'Vehicle Details': 'වාහන විස්තර',
  'Select Year': 'වසර තෝරන්න',
  'Owner Details': 'හිමිකරු විස්තර',
  'User Details': 'පරිශීලක විස්තර',
  'Vehicle Number': 'වාහන අංකය',
  'Vehicle Model': 'වාහන ආකෘතිය',
  'Year Of Manufacture': 'නිෂ්පාදිත වර්ෂය',
  Mileage: 'සැතපුම් ගණන',
  'Year of Register': 'ලියාපදිංචි වර්ෂය',
  enterManu_Year: 'නිෂ්පාදිත වර්ෂය ඇතුළත් කරන්න',
  'Engine Number': 'එන්ජින් අංකය',
  'Enter Engine Number': 'එන්ජින් අංකය ඇතුලත් කරන්න',
  'Chassis Number': 'චැසි අංකය',
  'Enter Chassis Number': 'චැසි අංකය ඇතුලත් කරන්න',
  battery: 'බැටරි වර්ගය/අංකය',
  modelNo: 'මාදිලි අංකය',
  'Tyre Size': 'ටයර් ප්රමාණය',
  'Enter Tyre Size': 'ටයර් ප්‍රමාණය ඇතුළත් කරන්න',
  'Owner Name': 'අයිතිකරුගේ නම',
  'Enter Owner Name': 'අයිතිකරුගේ නම ඇතුලත් කරන්න',
  'Contact Number': 'ඇමතුම් අංකය',
  'Enter Contact Number': 'සම්බන්ධතා අංකය ඇතුළත් කරන්න',
  Email: 'විද්යුත් තැපෑල',
  'Enter Email': 'ඊමේල් ඇතුලත් කරන්න',
  'Select Fuel Type': 'ඉන්ධන වර්ගය තෝරන්න',
  'Select Insurance Company': 'රක්ෂණ සමාගම තෝරන්න',
  'Vehicle Registration Number': 'වාහන ලියාපදිංචි අංකය',
  'Battery Replacement Date': 'බැටරි ප්‍රතිස්ථාපන දිනය',
  'Enter Battery Replacement Date': 'බැටරි ප්‍රතිස්ථාපන දිනය ඇතුළත් කරන්න',
  Save: 'සුරකින්න',
  Cancel: 'අවලංගු කරන්න',
  home: 'උපකරණ පුවරුව',
  booking: 'වෙන් කිරීම්',
  Appointments: 'වෙන් කිරීම්',
  Jobinprogress: 'රැකියා',
  createBooking: 'පත්වීම්',
  inventory: 'ඉන්වෙන්ටරි',
  'Add Item': 'අයිතමය එකතු කරන්න',
  'Edit Item': 'අයිතමය සංස්කරණය කරන්න',
  'Goods Received': 'භාණ්ඩ ලැබුනා',
  'Download Template': 'අච්චුව බාගන්න',
  admin: 'පරිපාලක',
  jobCreation: 'රැකියා නිර්මාණය',
  regiNo: 'ලියාපදිංචි අංකය',
  'Enter Plate Number': 'තහඩු අංකය ඇතුළත් කරන්න',
  'Enter Vehicle Model': 'වාහන ආකෘතිය ඇතුළු කරන්න',
  'Color Code': 'වර්ණ කේතය',
  'Enter Color Code': 'වර්ණ කේතය ඇතුළත් කරන්න',
  'Enter Battery Rate': 'බැටරි අනුපාතය ඇතුලත් කරන්න',
  'Fuel Type': 'ඉන්ධන වර්ගය',
  'Enter Fuel Type': 'ඉන්ධන වර්ගය ඇතුළත් කරන්න',
  'Enter License Number': 'බලපත්ර අංකය ඇතුළත් කරන්න',
  'License Details': 'බලපත්ර විස්තර',
  'Enter License Details': 'බලපත්‍ර විස්තර ඇතුලත් කරන්න',
  licenseNumber: 'බලපත්ර අංකය',
  'License Renewal Date': 'බලපත්‍ර අලුත් කිරීමේ දිනය',
  'Insurance Details': 'රක්ෂණ විස්තර',
  insuranceNumber: 'රක්ෂණ අංකය',
  'Insurance Renewal Date': 'රක්ෂණ අලුත් කිරීමේ දිනය',
  'Vehicle User Name': 'වාහනයේ පරිශීලක නාමය',
  'Enter Insurance Details': 'රක්ෂණ විස්තර ඇතුලත් කරන්න',
  'Enter User Name': 'පරිශීලක නාමය ඇතුළත් කරන්න',
  submit: 'ඉදිරිපත් කරන්න',
  logOut: 'පිටවීම',
  stdHours: 'සම්මත වේලාව',
  Quantity: 'ප්රමාණය',
  Total: 'සමස්ත:',
  price: 'මිල',
  // translations for global part search
  'Global Parts Search': 'ගෝලීය කොටස් සෙවීම',
  // Job Creation page translations
  'Select File': 'ගොනුව තෝරන්න',
  'Edit Job Details': 'රැකියා විස්තර සංස්කරණය කරන්න',
  'Applicable Vehicle Make': 'අදාළ වාහන සෑදීම',
  'Applicable Vehicle Model': 'අදාළ වාහන ආකෘතිය',
  'Checklist Have (1) / Don’t Have (0)': 'පිරික්සුම් ලැයිස්තුව ඇත (1) / නැත (0)',
  LH: 'LH',
  RH: 'RH',
  FRT: 'FRT',
  RER: 'RER',
  'Price Rs.': 'මිල රු.',
  'Labour Rate Type': 'ශ්රම අනුපාත වර්ගය',
  'Labour rate Rs.': 'ශ්රම අනුපාතය රු.',
  'STD Hrs (min)': 'STD Hrs (min)',
  'Assistance Allowed (1) / Not Allowed (0)': 'සහාය සඳහා අවසර ඇත (1) / අවසර නැත (0)',
  'Main Tech Incentive': 'ප්රධාන තාක්ෂණික දිරිගැන්වීම',
  'Assistant Tech Incentive (Rs.)': 'සහකාර තාක්ෂණ දිරි දීමනාව (රු.)',
  'Job Category': 'රැකියා කාණ්ඩය',
  'Main Tech Group 1': 'ප්‍රධාන තාක්ෂණික කණ්ඩායම 1',
  'Main Tech Group 2': 'ප්‍රධාන තාක්ෂණික කණ්ඩායම 2',
  'Assistant Tech Group 2': 'සහකාර තාක්ෂණ කණ්ඩායම 2',
  'Active Status': 'ක්රියාකාරී තත්ත්වය',
  'Tax 1 (VAT %)': 'Tax 1 (VAT %)',
  'Tax 2 (NBT %)': 'Tax 2 (NBT %)',
  'Tax 3 (Other %)': 'Tax 3 (Other %)',
  'Income Account Name': 'ආදායම් ගිණුමේ නම',
  'P & L': 'P & L',
  'INSTRUCTIONS / Notes': 'උපදෙස් / සටහන්',
  'Images (Hyper Link)': 'පින්තූර (HyperLink)',
  'Videos (HyperLink)': 'වීඩියෝ (HyperLink)',
  'Add Job Details': 'රැකියා විස්තර එකතු කරන්න',
  'Vehicle Category': 'වාහන කාණ්ඩය',
  'Enter Applicable Vehicle Make': 'අදාළ වාහන සෑදීම ඇතුළත් කරන්න',
  'Enter Applicable Vehicle Model': 'අදාළ වාහන ආකෘතිය ඇතුළත් කරන්න',
  'Enter Vehicle Category': 'වාහන කාණ්ඩය ඇතුළු කරන්න',
  'Enter Check List Have (1) / Don’t Have (0)': 'පිරික්සුම් ලැයිස්තුව ඇතුලත් කරන්න ඇත (1) / නැත (0)',
  'Enter Description': 'විස්තරය ඇතුලත් කරන්න',
  'Enter LH': 'ඇතුල් කරන්න LH',
  'Enter RH': 'ඇතුල් කරන්න RH',
  'Enter FRT': 'ඇතුල් කරන්න FRT',
  'Enter RER': 'ඇතුල් කරන්න RER',
  'Enter Price': 'මිල ඇතුල් කරන්න',
  'Select a person': 'පුද්ගලයෙකු තෝරන්න',
  'Enter Rate': 'අනුපාතය ඇතුළත් කරන්න',
  'Enter Enter STD hours': 'ඇතුල් කරන්න(STD hours)',
  'Enter Tax': 'බදු ඇතුලත් කරන්න',
  'Enter Main Tech Incentive': 'ප්‍රධාන තාක්ෂණික දිරිගැන්වීම ඇතුළත් කරන්න',
  'Enter Assistant Tech Incentive': 'සහකාර තාක්ෂණ දිරි දීමනාව ඇතුළත් කරන්න',
  'Enter Job Category': 'රැකියා කාණ්ඩය ඇතුළත් කරන්න',
  'Enter Main Tech Group 1': 'ප්‍රධාන තාක්ෂණික කණ්ඩායම 1 ඇතුළු කරන්න',
  'Enter Main Tech Group 2': 'ප්‍රධාන තාක්ෂණික කණ්ඩායම 2 ඇතුළු කරන්න',
  'Enter Assistant Tech Group 2': 'සහකාර තාක්ෂණ කණ්ඩායම 2 ඇතුළු කරන්න',
  'Enter Active Status': 'ක්‍රියාකාරී තත්ත්වය ඇතුළු කරන්න',
  'Enter Income Account Name': 'ආදායම් ගිණුමේ නම ඇතුලත් කරන්න',
  'Enter P & L': 'ඇතුලත් කරන්න P & L',
  'Enter INSTRUCTIONS / Notes': 'උපදෙස් / සටහන් ඇතුලත් කරන්න',
  'Enter Images (Hyper Link)': 'පින්තූර ඇතුලත් කරන්න (HyperLink)',
  'Enter Videos (HyperLink)': 'වීඩියෝ ඇතුලත් කරන්න (HyperLink)',
  upload: 'උඩුගත කරන්න',
  'Please Enter Applicable Vehicle Make': 'කරුණාකර අදාළ වාහන සෑදීම ඇතුළු කරන්න',
  'Please Enter Applicable Vehicle Model': 'කරුණාකර අදාළ වාහන ආකෘතිය ඇතුළු කරන්න',
  'Please Enter Vehicle Category': 'කරුණාකර වාහන කාණ්ඩය ඇතුළු කරන්න',
  'Input should be a one digit number': 'ආදානය එක් ඉලක්කම් අංකයක් විය යුතුය',
  'Please Enter Description': 'කරුණාකර විස්තරය ඇතුලත් කරන්න',
  'Please Enter LH': 'කරුණාකර ඇතුලත් කරන්න LH',
  'Please Enter RH': 'කරුණාකර ඇතුලත් කරන්න RH',
  'Please Enter FRT': 'කරුණාකර ඇතුලත් කරන්න FRT',
  'Please Enter RER': 'කරුණාකර ඇතුලත් කරන්න RER',
  'Please Enter Price': 'කරුණාකර මිල ඇතුළත් කරන්න',
  'Please Enter Labour Rate Type': 'කරුණාකර ශ්‍රම අනුපාත වර්ගය ඇතුළු කරන්න',
  'Please Enter Rate': 'කරුණාකර අනුපාතය ඇතුලත් කරන්න',
  'Please Enter Enter STD hours': 'කරුණාකර STD වේලාවන් ඇතුළත් කරන්න',
  'Input should be a one digit number ': 'ආදානය එක් ඉලක්කම් අංකයක් විය යුතුය ',
  'Please Enter Main Tech Incentive': 'කරුණාකර ප්‍රධාන තාක්ෂණික දිරිගැන්වීම ඇතුළත් කරන්න',
  'Please Enter Assistant Tech Incentive': 'කරුණාකර සහකාර තාක්ෂණ දිරි දීමනාව ඇතුළත් කරන්න',
  'Please Enter Job Category': 'කරුණාකර රැකියා ප්‍රවර්ගය ඇතුළු කරන්න',
  'Please Enter Main Tech Group 1': 'කරුණාකර ප්‍රධාන තාක්ෂණික කණ්ඩායම ඇතුළත් කරන්න 1',
  'Please Enter Main Tech Group 2': 'කරුණාකර ප්‍රධාන තාක්ෂණික කණ්ඩායම ඇතුළත් කරන්න 2',
  'Please Enter Assistant Tech Group 2': 'කරුණාකර සහකාර තාක්ෂණ කණ්ඩායම ඇතුළු කරන්න 2',
  'Please Enter Active Status': 'කරුණාකර ක්‍රියාකාරී තත්ත්වය ඇතුළු කරන්න',
  'Please Enter Tax': 'කරුණාකර බදු ඇතුලත් කරන්න',
  'Please Enter Income Account Name': 'කරුණාකර ආදායම් ගිණුමේ නම ඇතුලත් කරන්න',
  'Please Enter P & L': 'කරුණාකර ඇතුලත් කරන්න P & L',
  'Please Enter INSTRUCTIONS / Notes': 'කරුණාකර උපදෙස් / සටහන් ඇතුළත් කරන්න',
  'Please Enter Images (Hyper Link)': 'කරුණාකර පින්තූර ඇතුලත් කරන්න (Hyper Link)',
  jobName: 'රැකියා නම',
  'Input should be a number ': 'ආදානය අංකයක් විය යුතුය ',
  'Please Enter Checklist Have (1) / Don’t Have (0)': 'කරුණාකර පිරික්සුම් ලැයිස්තුව ඇතුළත් කරන්න (1) / නැත (0)',
  subSection: 'උප කොටස',
  sectionDescription: 'සෑම රැකියා විස්තරයක්ම එක් වරකට එක් රැකියාවක් විස්තර කරන්න',
  qty: 'Qty or hours',
  addJob: 'රැකියාව එකතු කරන්න',
  jobList: 'රැකියා ලැයිස්තුව',
  'Please enter a valid number': 'කරුණාකර වලංගු අංකයක් ඇතුළත් කරන්න',
  Edit: 'සංස්කරණය කරන්න',
  'Upload File': 'ගොනුව උඩුගත කරන්න',
  // validation messages
  regiNoMessage: 'කරුණාකර වාහන අංකය ඇතුළත් කරන්න',
  jobTypeMessage: 'කරුණාකර රැකියා වර්ගය තෝරන්න',
  bookingDateMessage: 'කරුණාකර වෙන් කිරීමේ දිනයක් තෝරන්න',
  bookingTimeMessage: 'කරුණාකර වෙන් කිරීමේ වේලාවක් තෝරන්න',
  selectItemMessage: 'කරුණාකර අයිතමයක් තෝරන්න',
  inputRateMessage: 'කරුණාකර ආදාන අනුපාතය',
  qtyHoursMessae: 'කරුණාකර qty_Hours ඇතුළත් කරන්න',

  /* tour messages */
  'Skip Button': 'මඟ හරින්න',
  // dashboard tour
  heyThere: 'ඒයි මේ..!',
  welcomeMessage: 'ඔබගේ වැඩමුළුවට සාදරයෙන් පිළිගනිමු.',
  areYouReady: 'ඔබේ වැඩමුළුව සොයා ගැනීමට ඔබ සූදානම්ද..?',
  selectLanguageHere: 'ඔබ වඩාත්ම කැමති භාෂාව මෙතැනින් තෝරන්න..',
  viewNotification: 'දැනුම්දීම් බලන්න',
  viewNotificationHere: 'ඔබට මෙතැනින් දැනුම්දීම නැරඹිය හැක..',
  youAreonDashboard: 'ඔබ වැඩමුළු උපකරණ පුවරුවේ සිටී!',

  // create booking tour
  'Are you ready to create a Booking ?': 'ඔබ වෙන් කිරීමක් නිර්මාණය කිරීමට සූදානම්ද?',
  'Make an appointment': 'හමුවීමක් කරන්න',
  'Select a date from the calendar view': 'දින දර්ශන දසුනෙන් දිනයක් තෝරන්න',
  'Fill the form to make an appointment': 'හමුවීමක් කිරීමට පෝරමය පුරවන්න',
  'Add new Vehicle': 'නව වාහනයක් එක් කරන්න',
  'Click here to add a new vehicle, if the vehicle is not registerd in Karstation': 'වාහනය කාර්ස්ටේෂන් හි ලියාපදිංචි කර නොමැති නම්, නව වාහනයක් එක් කිරීමට මෙතැන ක්ලික් කරන්න',
  'Search vehicle': 'වාහනය සොයන්න',
  'If the vehicle is already registered, search vehicle with plate number': 'වාහනය දැනටමත් ලියාපදිංචි වී ඇත්නම්, තහඩු අංකය සමඟ වාහනය සොයන්න',
  // dashboard
  dashboard: 'උපකරණ පුවරුව',
  // view task
  'Booking Details': 'වෙන්කරවා ගැනීමේ විස්තර',
  Jobtype: 'රැකියා වර්ගය',
  Year: 'වසර',
  'Battery Rate': 'බැටරි අනුපාතය',
  Name: 'නම',
  Type: 'වර්ගය',
  Standardhoursmin: 'සම්මත වේලාව',
  Qty: 'Qty',
  Rate: 'අගය',
  Price: 'මිල',
  Status: 'තත්ත්වය',
  Assignee: 'පැවරුම්කරු',
  Comment: 'අදහස් දක්වන්න',
  Qcstatus: ' QC තත්ත්වය',
  'Booking History': 'වෙන්කිරීමේ ඉතිහාසය',

  // view task investigation
  'Make a Check-In': 'ඇතුල්වීමක් කරන්න',
  Images: 'රූප',
  Platenumber: ' තහඩු අංකය',
  Vmodel: ' මාදිලිය',
  Vehiclemodel: ' වාහන ආකෘතිය',
  VehicleMake: ' වාහන සෑදීම',
  Enginenumber: ' එන්ජින් අංකය',
  Chassisnumber: ' චැසි අංකය',
  Tyresize: ' ටයර් ප්රමාණය',
  Batteryrate: ' බැටරි අනුපාතය',
  Fueltype: ' ඉන්ධන වර්ගය',
  Contactnumber: ' ඇමතුම් අංකය',
  'Job in progress': ' සිදුවෙමින් පවතින කාර්යයන්',
  Bookinghistory: ' වෙන්කිරීමේ ඉතිහාසය',
  // view task investigation page
  MakeaCheckIn: ' වාහන පරීක්ෂා කිරීම',

  // chech in page
  Investigation: 'පරීක්ෂණයක්',
  'Add Comments': 'අදහස් එකතු කරන්න',
  'Generate Report': 'වාර්තාව ජනනය කරන්න',
  Approved: 'අනුමත කළා',
  Comments: 'අදහස්',
  'Submit to Customer': 'පාරිභෝගිකයා වෙත ඉදිරිපත් කරන්න',
  'Resend Email': 'ඊමේල් නැවත යවන්න',
  // inventory page
  Category: 'වර්ගය',
  PriceRS: ' මිල',
  'Part Number': 'කොටස් අංකය',
  'Part Name': 'කොටස නම',
  'Other Names': 'වෙනත් නම්',
  'Description / Details': 'විස්තරය / විස්තර',
  'Warehouse Type': 'ගබඩා වර්ගය',
  'Bin Location': 'බින් ස්ථානය',
  'Anticipated Usage': 'අපේක්ෂිත භාවිතය',
  Action: 'කටයුතු',
  'Item Type': 'අයිතමය වර්ගය',
  'Supplier Information': 'සැපයුම්කරු තොරතුරු',
  'Goods In': 'භාණ්ඩ තුළ',
  'Goods Out': 'බඩු ඉවරයි',
  'Warranty Details': 'වගකීම් විස්තර',
  'Browse Image (Optional)': 'රූපය බ්‍රවුස් කරන්න (විකල්ප)',
  'Min Order Quantity To Supplier': 'සැපයුම්කරුට අවම ඇණවුම් ප්‍රමාණය',
  'Select Item Type': 'අයිතම වර්ගය තෝරන්න',
  'Enter Part Number': 'කොටස් අංකය ඇතුලත් කරන්න',
  'Enter Part Name': 'කොටසේ නම ඇතුළත් කරන්න',
  'Enter Supplier Information': 'සැපයුම්කරුගේ තොරතුරු ඇතුළත් කරන්න',
  'Enter Other Names': 'වෙනත් නම් ඇතුලත් කරන්න',
  'Enter Warehouse Type': 'ගබඩා වර්ගය ඇතුළත් කරන්න',
  'Enter Bin Location': 'Bin ස්ථානය ඇතුළු කරන්න',
  'Enter Anticipated Usage': 'අපේක්ෂිත භාවිතය ඇතුළත් කරන්න',
  'Enter Warranty Details': 'වගකීම් විස්තර ඇතුළත් කරන්න',
  'Enter Min Order Quantity-To-Supplier': 'සැපයුම්කරුට අවම ඇණවුම් ප්‍රමාණය ඇතුළත් කරන්න',
  'Browse Image': 'රූපය පිරික්සන්න',
  'Labour Rate': 'ශ්රම අනුපාතය',
  'Labour Rate Rs': 'ශ්‍රම මිල රු.',
  'Checklist Availability': 'පිරික්සුම් ලැයිස්තුව ලබා ගැනීමේ හැකියාව',
  // CheckListAvailabilit: ' Check List Availabilit',
  'Assistance Allowed': 'උපකාර කිරීමට අවසර ඇත',
  'Assistant Tech Incentive': 'සහකාර තාක්ෂණික දිරිගැන්වීම',
  'Tax 2 (NBT 2)': 'බදු 2 (NBT 2)',
  'Tax 3 (Other)': 'බදු 3 (වෙනත්)',
  'Videos (Hyper Link)': 'වීඩියෝ (අධි සබැඳිය)',
  Inventory: 'ඉන්වෙන්ටරි',
  Jobs: 'රැකියා',
  // staff page
  EmployeeID: 'සේවක හැදුනුම්පත',
  Next: 'ඊළඟ',
  Back: 'ආපසු',
  'Date of Birth': 'උපන්දිනය',
  Address: 'ලිපිනය',
  'Employee Name': 'සේවකයාගේ නම',
  'Enter Account Holder Name': 'ගිණුම් හිමියාගේ නම ඇතුළත් කරන්න',
  'Enter Employee Bank': 'සේවක බැංකුව ඇතුළු කරන්න',
  'Enter The Branch': 'ශාඛාව ඇතුළු කරන්න',
  'Enter Account Number': 'ගිණුම් අංකය ඇතුලත් කරන්න',
  'Enter Basic Salary': 'මූලික වැටුප ඇතුලත් කරන්න',
  'Enter EPF': 'EPF ඇතුලත් කරන්න',
  'Enter ETF': 'ETF ඇතුලත් කරන්න',
  'Please Enter Account Holder Name': 'කරුණාකර ගිණුම් හිමියාගේ නම ඇතුළත් කරන්න',
  'Please Enter Employee Bank': 'කරුණාකර සේවක බැංකුව ඇතුළු කරන්න',
  'Please Enter Branch': 'කරුණාකර ශාඛාව ඇතුළු කරන්න',
  'Please Enter Account Number': 'කරුණාකර ගිණුම් අංකය ඇතුලත් කරන්න',
  'Please Enter Basic Salary': 'කරුණාකර මූලික වැටුප ඇතුළත් කරන්න',
  'Enter Employee Address': 'සේවක ලිපිනය ඇතුලත් කරන්න',
  Role: 'කාර්යභාරය',
  'Please Enter EPF': 'කරුණාකර ඇතුලත් කරන්න EPF',
  'Please Enter ETF': 'කරුණාකර ඇතුලත් කරන්න ETF',
  'G.C.E. O/L Certificate  [NVQ4]': 'G.C.E. O/L Certificate  [NVQ4]',
  'G.C.E A/L Certificate  [NVQ5]': 'G.C.E A/L Certificate  [NVQ5]',
  'NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]': 'NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]',
  'BSc (Engineering)/BEng Automobile [NVQ 7]': 'BSc (Engineering)/BEng Automobile [NVQ 7]',
  'Msc Engineering/ MEng Automobile': 'Msc Engineering/ MEng Automobile',
  'Other Professional  Registrations': 'වෙනත් වෘත්තීය ලියාපදිංචි කිරීම්',
  'Incorporated Engineer Registration': 'සංස්ථාපිත ඉංජිනේරු ලියාපදිංචිය',
  'Charted Engineer Registration': 'වරලත් ඉංජිනේරු ලියාපදිංචිය',
  Browse: 'බ්‍රවුස් කරන්න',
  'Please Enter Employee Name': 'කරුණාකර සේවක නම ඇතුලත් කරන්න',
  'Please Select Employee Role': 'කරුණාකර සේවක භූමිකාව තෝරන්න',
  'Please Enter Employee NIC Number': 'කරුණාකර සේවක ජාතික හැඳුනුම්පත් අංකය ඇතුළත් කරන්න',
  'Please Enter Employee Contact Number': 'කරුණාකර සේවක සම්බන්ධතා අංකය ඇතුළත් කරන්න',
  'Please Enter User Name': 'කරුණාකර පරිශීලක නාමය ඇතුළත් කරන්න',
  'Please Select Status': 'කරුණාකර තත්ත්වය තෝරන්න',
  'Contact No': 'සම්බන්ධතා අංකය',
  'Enter Employee Name': 'සේවක නම ඇතුලත් කරන්න',
  'Select Employee Role': 'සේවක භූමිකාව තෝරන්න',
  'Enter NIC Number': 'NIC අංකය ඇතුලත් කරන්න',
  'Select Date of Birth': 'උපන් දිනය තෝරන්න',
  Username: 'පරිශීලක නාමය',
  'Please Enter Date of Birth': 'කරුණාකර උපන් දිනය ඇතුළත් කරන්න',
  Staff: 'කාර්ය මණ්ඩලය',
  // workshops
  AddNewWorkshops: 'නව වැඩමුළුව එක් කරන්න',
  Workshops: 'වැඩමුළු',
  WorkshopID: 'වැඩමුළු ID',
  WorkshopName: 'වැඩමුළුවේ නම',
  WorkshopRegistrationNumber: 'වැඩමුළු ලියාපදිංචි අංකය',
  WorkshopContactDetails: 'වැඩමුළුව සම්බන්ධතා විස්තර',
  // main page
  History: 'ඉතිහාසය',
  GlobalParts: 'ගෝලීය කොටස්',
  'Add an Employee': 'සේවකයෙකු එක් කරන්න',
  EmployeeName: ' සේවකයාගේ නම',
  'Employee Role': 'සේවක භූමිකාව',
  'NIC Number': 'ජාතික හැඳුනුම්පත් අංකය',
  'Date of Birth (Optional)': 'උපන් දිනය (විකල්ප)',
  'Address (Optional)': 'ලිපිනය (විකල්ප)',
  'User Name': 'පරිශීලක නාමය',
  General: ' පොදු',
  BrowseImage: ' රූපය බ්‍රවුස් කරන්න (විකල්ප)',
  Salary: ' වැටුප',
  'Account Holder Name': 'ගිණුම් හිමියාගේ නම',
  'Employee Bank': 'සේවක බැංකුව',
  Branch: 'ශාඛාව',
  'Account Number': 'ගිණුම් අංකය',
  'Basic Salary': 'මූලික වැටුප',
  Incentives: 'සහන',
  Qualifications: ' සුදුසුකම්',
  'Configuration Data': 'මානකරන දත්ත',
  'Job Types': 'රැකියා වර්ග',
  Password: ' මුරපදය',
  Bank: 'බැංකුව',
  Currency: 'මුදල්',
  'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.': 'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.',
  'CHECK-IN': 'ඇතුල් වීම',
  'PENDING CUSTOMER APPROVAL': 'පොරොත්තු පාරිභෝගික අනුමැතිය',
  'JOBS IN QUEUE': 'රැකියා පෝලිමේ',
  breakdown: 'බිඳ වැටීම්',
  repair: 'අලුත්වැඩියා',
  service: 'සේවාව',
  accident: 'අනතුරක්',
  AccountHolderName: ' Account Holder Name',
  EmployeeBank: ' Employee Bank',
  AccountNumber: ' Account Number',
  BasicSalary: ' මූලික වැටුප',
  Qulifications: ' සුදුසුකම්',
  View: 'තව බලන්න',
  'Passed with conditions': 'කොන්දේසි සහිතව සම්මත කර ඇත',
  Rejected: 'ප්‍රතික්ෂේප කළා',
  Search: 'සොයන්න',
  From: 'සිට',
  To: 'දක්වා',
  'IN PROGRESS': 'ප්රගතියේ',
  'QUALITY CONTROL': 'තත්ත්ව පාලනය',
  PAYMENTS: 'ගෙවීම්',
  'Jobs In Queue': 'රැකියා පෝලිමේ',
  'In Progress': 'ප්රගතියේ',
  'Quality Control': 'තත්ත්ව පාලනය',
  'Pending Payment': 'පොරොත්තු ගෙවීම්',
  Accident: 'අනතුරක්',
  Repair: 'අලුත්වැඩියා',
  Breakdown: 'බිඳ වැටීම්',
  Service: 'සේවාව',
  'Standard Hours(min)': 'සම්මත පැය (විනාඩි)',
  Start: 'ආරම්භ කරන්න',
  Pause: 'නවත්වන්න',
  Done: 'කළා',
  'Select the Reason': 'හේතුව තෝරන්න',
  Pay: 'ගෙවන්න',
  Cash: 'මුදල්',
  Online: 'ඔන්ලයින්',
  'QC Status': 'QC තත්ත්වය',
  Pass: 'සමත්',
  Fail: 'අසමත්',
  'Assign to..': 'පවරන්න..',
  'Submit to Pending Payment': 'පොරොත්තු ගෙවීමට ඉදිරිපත් කරන්න',
  'Submit to In-Progress': 'ක්‍රියාත්මක වෙමින් පවතින වෙත ඉදිරිපත් කරන්න',
  'Submit to QC': 'QC වෙත ඉදිරිපත් කරන්න',
  Invoice: 'ඉන්වොයිසිය',
  Settings: 'සැකසුම්',
  'All jobs need to be completed to proceed': 'ඉදිරියට යාමට සියලු කාර්යයන් සම්පූර්ණ කළ යුතුය',
  'To Submit there should be at least one job started or done': 'ඉදිරිපත් කිරීමට අවම වශයෙන් එක් කාර්යයක් ආරම්භ කර හෝ කර තිබිය යුතුය',
  'To continue, all jobs should be QC passed or use the passed with conditions option': 'දිගටම කරගෙන යාමට, සියලුම රැකියා QC සමත් විය යුතුය, නැතහොත් කොන්දේසි සහිත Passed විකල්පය භාවිතා කරන්න',
  'Back to Job In Queue': 'කාර්යයන් පැවතියෙන් ආරම්භ කරන්න',
  'QC Passed': 'QC සමත්',
  'To Do': 'රැකියා පෝලිමේ',
  Quickjobs: 'ඉක්මන් රැකියා',
  'Please select a group': 'කරුණාකර කණ්ඩායමක් තෝරන්න',
  'Please input item name': 'කරුණාකර අයිතමයේ නම ඇතුළත් කරන්න',
  'Repair Item Groups': 'අලුත්වැඩියා අයිතම කණ්ඩායම්',
  'Please select form': 'කරුණාකර පෝරමය තෝරන්න',
  'Select the form': 'පෝරමය තෝරන්න',
  'Export CSV': 'අපනයන. CSV',
};
