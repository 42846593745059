export const CONFIG = {
  stage: 'qa',
  baseApiUrl: 'https://api.qa.karstation.com',
};

export const AWS_CONFIG = {
  Auth: {
    region: 'us-west-1',
    userPoolId: 'us-west-1_Ab487fBTk',
    identityPoolId: 'us-west-1:aab04dd4-96ea-4ac3-8fa0-7c49eec9a154',
    userPoolWebClientId: 'gkp63b57gmej3g2obl9u3eb2o',
    mandatorySignIn: false,
  },
  aws_appsync_graphqlEndpoint: 'https://bpibje3minf2hmihrxvxlvu2fe.appsync-api.us-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
