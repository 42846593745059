/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  BarsOutlined,
  BellOutlined,
  CarOutlined,
  CarryOutOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  GlobalOutlined,
  HistoryOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  StockOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar, Button, Col, Dropdown, Layout, Menu, Popover, Row,
} from 'antd';
import {
  Link, Route, Switch, useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { checkIsLoggedIn } from '../login/actions';
import AddBookingPage from '../booking/appointments/AppointmentsPage';
import { useAppDispatch } from '../../redux/hooks';
import InventoryPage from '../admin/inventory/InventoryPage';
import AddJobCreation from '../admin/jobCreation/JobCreation';
import JobTaskBoard from '../booking/viewJobs/JobTaskBoard';
import CheckIn from '../booking/investigation/checkIn';
import History from '../booking/bookingHistory/HistoryPage';
import StaffPage from '../admin/staff/StaffPage';
import GlobalPartsSearch from '../globalPartsSearch/GlobalPartsSearch';
import Workshop from '../workshopProfile/WorkshopProfile';
import LoginPage from '../login/LoginPage';
import i18n from '../../translation/i18n';
import ForgetPasswordPage from '../login/forgetPassword/ForgetPasswordPage';
import ConfigurationData from '../admin/settings/Settings';
import { setStaffPage } from '../../redux/userSlice';
import Credit from '../booking/credit/Credit';
import Finance from '../admin/finance/Finance';
import LanguageMenu from './components/LanguageMenu';
import UserDetails from './components/UserDetails';
import { USER_ROLES } from '../../enum/User';
import DashboardPage from '../dashboard/DashboardPage';
import GrnInvoice from '../admin/invoicing/GRNInvoice';

const { SubMenu } = Menu;
const {
  Header, Content, Sider,
} = Layout;

function Main({ userDetails }: { userDetails: any }) {
  const {
    user: userData,
    userId,
    userRoles: roleData,
    workshop,
    configData,
    isStaffPage,
  } = userDetails;
  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [workshopName, setWorkshopName] = useState<string>('K A R S T A T I O N');
  const [defaultMenu, setDefaultMenu] = useState();
  const userRoles = roleData !== undefined ? Object.values(roleData) : '';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onMenuChange = (item) => {
    localStorage.setItem('selectedMenu', item.keyPath);
    if (!(item.keyPath[0] === '10' && item.keyPath[1] === 'admin')) {
      dispatch(setStaffPage(false));
    }
  };

  const hasRole = (roles: USER_ROLES[]) => roles.some((role: USER_ROLES) => userRoles.includes(role));

  useEffect(() => {
    checkIsLoggedIn(dispatch);
    const lang = localStorage.getItem('lang');
    i18n.changeLanguage(lang || 'En');
    const selectedMenu: any = (localStorage.getItem('selectedMenu') || '1').split(',');
    setDefaultMenu(selectedMenu);
  }, []);

  useEffect(() => {
    if (workshop && workshop.tradeName) {
      setWorkshopName(workshop.tradeName.toLocaleUpperCase().split('').join(' '));
    }
  }, [workshop]);

  useEffect(() => {
    if (isStaffPage) {
      history.replace({ pathname: '/staff' }, { from: '/' });
    }
  }, [isStaffPage]);

  return (
    <Layout>
      <Sider data-tour="main-menu" className="sider-menu" trigger={null} collapsible collapsed={collapsed}>
        <div style={{ height: '80%' }}>
          <div className="logo">
            {!collapsed && <h1 style={{ color: '#D5DAE3', fontWeight: 'bolder' }}>{workshopName}</h1>}
          </div>
          {defaultMenu && (
          <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultMenu} className="ks-menu-item" onSelect={onMenuChange}>
            {userId && !hasRole([USER_ROLES.KARSTATION_ADMIN]) && (
            <>
              {!hasRole([USER_ROLES.TECHNICIAN]) && (
              <Menu.Item key="1" icon={<HomeOutlined style={{ fontSize: '150%' }} />}>
                <Link to="/">{t('home')}</Link>
              </Menu.Item>
              )}
              <SubMenu key="booking" icon={<FileDoneOutlined style={{ fontSize: '150%' }} />} title={t('booking')} className="ks-menu-item">
                {userId && hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST]) && (
                <Menu.Item key="7" icon={<FileAddOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/appointments">{t('Appointments')}</Link>
                </Menu.Item>
                )}
                <Menu.Item key="8" icon={<CarOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/investigation">{t('Investigation')}</Link>
                </Menu.Item>
                <Menu.Item key="9" icon={<CarryOutOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/jobs">{t('Job in progress')}</Link>
                </Menu.Item>
                {userId && !hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.SERVICE_ADVISOR]) && (
                <Menu.Item key="16" icon={<CreditCardOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/credit">{t('Credit')}</Link>
                </Menu.Item>
                )}
                {userId && !hasRole([USER_ROLES.TECHNICIAN]) && (
                <Menu.Item key="12" icon={<HistoryOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/booking-history">{t('History')}</Link>
                </Menu.Item>
                )}
              </SubMenu>
              {userId && hasRole([USER_ROLES.ADMIN, USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.RECEPTIONIST]) && (
              <SubMenu key="admin" icon={<AppstoreOutlined style={{ fontSize: '150%' }} />} title={t('admin')} className="ks-menu-item">
                <Menu.Item key="5" icon={<StockOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/inventory">{t('inventory')}</Link>
                </Menu.Item>
                <Menu.Item
                  key="17"
                  icon={(
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M16.2819 10.7189C16.3518 10.7885 16.4072 10.8713 16.445 10.9624C16.4828 11.0535 16.5023 11.1512 16.5023 11.2499C16.5023 11.3485 16.4828 11.4462 16.445 11.5373C16.4072 11.6284 16.3518 11.7112 16.2819 11.7809L11.7819 16.2809C11.7123 16.3507 11.6295 16.4061 11.5384 16.4439C11.4473 16.4818 11.3496 16.5012 11.2509 16.5012C11.1523 16.5012 11.0546 16.4818 10.9635 16.4439C10.8724 16.4061 10.7896 16.3507 10.7199 16.2809L8.46995 14.0309C8.40022 13.9611 8.3449 13.8784 8.30716 13.7873C8.26942 13.6961 8.25 13.5985 8.25 13.4999C8.25 13.4013 8.26942 13.3036 8.30716 13.2125C8.3449 13.1214 8.40022 13.0386 8.46995 12.9689C8.61078 12.828 8.80178 12.7489 9.00095 12.7489C9.09956 12.7489 9.19721 12.7684 9.28832 12.8061C9.37943 12.8438 9.46222 12.8991 9.53195 12.9689L11.2509 14.6894L15.2199 10.7189C15.2896 10.649 15.3724 10.5936 15.4635 10.5558C15.5546 10.518 15.6523 10.4985 15.7509 10.4985C15.8496 10.4985 15.9473 10.518 16.0384 10.5558C16.1295 10.5936 16.2123 10.649 16.2819 10.7189V10.7189Z" fill="white" fillOpacity="0.65" />
                      <path d="M6 2.25H4.5C3.70435 2.25 2.94129 2.56607 2.37868 3.12868C1.81607 3.69129 1.5 4.45435 1.5 5.25V21C1.5 21.7956 1.81607 22.5587 2.37868 23.1213C2.94129 23.6839 3.70435 24 4.5 24H19.5C20.2956 24 21.0587 23.6839 21.6213 23.1213C22.1839 22.5587 22.5 21.7956 22.5 21V5.25C22.5 4.45435 22.1839 3.69129 21.6213 3.12868C21.0587 2.56607 20.2956 2.25 19.5 2.25H18V3.75H19.5C19.8978 3.75 20.2794 3.90804 20.5607 4.18934C20.842 4.47064 21 4.85218 21 5.25V21C21 21.3978 20.842 21.7794 20.5607 22.0607C20.2794 22.342 19.8978 22.5 19.5 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V5.25C3 4.85218 3.15804 4.47064 3.43934 4.18934C3.72064 3.90804 4.10218 3.75 4.5 3.75H6V2.25Z" fill="white" fillOpacity="0.65" />
                      <path d="M14.25 1.5C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H9.75C9.55109 4.5 9.36032 4.42098 9.21967 4.28033C9.07902 4.13968 9 3.94891 9 3.75V2.25C9 2.05109 9.07902 1.86032 9.21967 1.71967C9.36032 1.57902 9.55109 1.5 9.75 1.5H14.25ZM9.75 0C9.15326 0 8.58097 0.237053 8.15901 0.65901C7.73705 1.08097 7.5 1.65326 7.5 2.25V3.75C7.5 4.34674 7.73705 4.91903 8.15901 5.34099C8.58097 5.76295 9.15326 6 9.75 6H14.25C14.8467 6 15.419 5.76295 15.841 5.34099C16.2629 4.91903 16.5 4.34674 16.5 3.75V2.25C16.5 1.65326 16.2629 1.08097 15.841 0.65901C15.419 0.237053 14.8467 0 14.25 0L9.75 0Z" fill="white" fillOpacity="0.65" />
                    </svg>
                  )}
                  className="ks-menu-item"
                >
                  <Link to="/grn">{t('GRN')}</Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<BarsOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                  <Link to="/job-creation">{t('jobCreation')}</Link>
                </Menu.Item>
                {!hasRole([USER_ROLES.RECEPTIONIST]) && (
                <>
                  <Menu.Item key="15" icon={<DollarCircleOutlined style={{ fontSize: '125%' }} />} className="ks-menu-item">
                    <Link to="/finance">{t('Finance')}</Link>
                  </Menu.Item>
                  <Menu.Item key="10" icon={<TeamOutlined style={{ fontSize: '125%' }} />}>
                    <Link to={isStaffPage ? '/staff' : '/login/staff'}>{t('Staff')}</Link>
                  </Menu.Item>
                  <Menu.Item key="14" icon={<SettingOutlined style={{ fontSize: '125%' }} />}>
                    <Link to="/settings">{t('Settings')}</Link>
                  </Menu.Item>
                </>
                )}
              </SubMenu>
              )}

              {(configData !== null && configData?.globalParts !== null) && !hasRole([USER_ROLES.RECEPTIONIST]) && (
              <Menu.Item key="11" icon={<FileSearchOutlined style={{ fontSize: '150%' }} />}>
                <Link to="/global-parts">{t('GlobalParts')}</Link>
              </Menu.Item>
              )}
            </>
            )}
            {userId && hasRole([USER_ROLES.KARSTATION_ADMIN]) && (
              <Menu.Item key="13" icon={<ApartmentOutlined style={{ fontSize: '150%' }} />}>
                <Link to="/workshop-profile">{t('Workshops')}</Link>
              </Menu.Item>
            )}
          </Menu>
          )}
        </div>
        <div className="kr-end">
          <p>© Powered by KARSTATION</p>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header className="ks-header">
          <Row>
            <Col span={18}>
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: onCollapse,
              })}
            </Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'right' }}>
              <Row>
                <Col span={1}>
                  <Button type="link">
                    <Avatar className="ky_vtr" icon={<BellOutlined />} />
                  </Button>
                </Col>
                <Col span={1}>
                  <Dropdown.Button
                    type="primary"
                    className="language-button"
                    overlay={<LanguageMenu />}
                    icon={<GlobalOutlined />}
                  >
                    {localStorage.getItem('lang')}
                  </Dropdown.Button>
                </Col>
                <Col span={1}>
                  <Popover
                    placement="bottomLeft"
                    content={(
                      <UserDetails
                        userId={userId}
                        user={userData}
                      />
                    )}
                    trigger="click"
                  >
                    <Button type="link" className="ky_btn_usr">
                      <Row>
                        <Avatar className="ky_vtr" icon={<UserOutlined />} />
                      </Row>
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content
          className="ks-content site-layout-background"
          style={{ overflow: 'auto' }}
        >
          <div className="bt-tag-mark">
            <img src="/assets/Beta2.png" className="ks_logo ks_logo_img_header_4" alt="beta" />
          </div>
          <Switch>
            {!userId && <Route path="/login"><LoginPage /></Route>}
            {!isStaffPage && <Route path="/login/:staff"><LoginPage /></Route>}
            {!userId && <Route path="/forget-password"><ForgetPasswordPage /></Route>}

            {/* Booking routes */}
            {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST]) && <Route path="/appointments"><AddBookingPage /></Route>}
            {!hasRole([USER_ROLES.KARSTATION_ADMIN]) && <Route path="/investigation"><CheckIn /></Route>}
            {!hasRole([USER_ROLES.KARSTATION_ADMIN]) && <Route path="/jobs"><JobTaskBoard /></Route>}
            {!hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.TECHNICIAN, USER_ROLES.SERVICE_ADVISOR]) && <Route path="/credit"><Credit /></Route>}
            {!hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.TECHNICIAN]) && <Route path="/booking-history"><History /></Route>}

            {/* admin routes */}
            {hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST]) && <Route path="/inventory"><InventoryPage /></Route>}
            {hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST]) && <Route path="/grn"><GrnInvoice /></Route>}
            {hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST]) && <Route path="/job-creation"><AddJobCreation /></Route>}
            {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN]) && <Route path="/finance"><Finance /></Route>}
            {isStaffPage && hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN]) && <Route path="/staff"><StaffPage /></Route>}
            {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN]) && <Route path="/settings"><ConfigurationData /></Route>}

            {!hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.RECEPTIONIST]) && <Route path="/global-parts"><GlobalPartsSearch /></Route>}

            {userId && hasRole([USER_ROLES.KARSTATION_ADMIN]) && <Route path="/workshop-profile"><Workshop /></Route>}
            {!hasRole([USER_ROLES.KARSTATION_ADMIN, USER_ROLES.TECHNICIAN]) && <Route path="/"><DashboardPage /></Route>}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

function mapStateToProps(state) {
  return {
    userDetails: state.userData,
  };
}

export default connect(mapStateToProps, {})(Main);
