export const getVehicle = `
query getVehicle($workShopId: String!, $searching: String!) {
  getVehicle(searching: $searching, workShopId: $workShopId) {
    colourCode
    batteryType
    batteryReplacementDate
    chassisNumber
    engineNumber
    fuelType
    insuranceNumber
    insuranceCompany
    insuranceRenevalDate
    licenseNumber
    licenseRenevalDate
    make
    workShopId
    vehicleId
    userTel
    userName
    userEmail
    tireSize
    searching
    plateNumber
    ownerTel
    ownerName
    ownerEmail
    model
    manufactureYear
    registerYear
    mileage
  }
}
`;

export const getAllBookingsForMonth = `
query getAllBookingsForMonth($workShopId: String!, $date1: String!, $date2: String!) {
  getAllBookingsForMonth(workShopId: $workShopId, date1: $date1, date2: $date2) {
    batteryType
    chassisNumber
    passWithConditions
    colourCode
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    progress
    bookingStdHrsMin
    images
    assigneeName
    assigneeId
    workShopId
    invoiceId
    updatedTime
    createdTime
    comment
    registerYear
    mileage
    paymentMethod
    bookingCompletedTime
    totalPrice
    total
  }
}
`;

export const updateVehicleQuarry = `
mutation updateVehicle($updateVehicle: UpdateVehicle) {
  updateVehicle(updateVehicle: $updateVehicle) {
    chassisNumber
    batteryType
    colourCode
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    ownerTel
    ownerName
    plateNumber
    searching
    tireSize
    userEmail
    userName
    userTel
    vehicleId
    workShopId
    registerYear
    mileage
  }
}
`;

export const addVehicle = `
mutation addVehicle($newVehicle: VehicleInput) {
  addVehicle(newVehicle: $newVehicle) {
    batteryType
    chassisNumber
    colourCode
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    ownerName
    ownerTel
    plateNumber
    searching
    tireSize
    userEmail
    userName
    userTel
    vehicleId
    workShopId
    registerYear
    mileage
  }
}
`;

export const addBooking = `
mutation addBooking($newBooking: [BookingInput]) {
    addBooking(newBooking: $newBooking) {
      batteryType
      chassisNumber
      colourCode
      engineNumber
      fuelType
      insuranceNumber
      insuranceRenevalDate
      jobType
      licenseNumber
      licenseRenevalDate
      make
      manufactureYear
      model
      ownerEmail
      ownerName
      ownerTel
      plateNumber
      selectedDate
      selectedTime
      sortKey
      status
      tireSize
      userEmail
      userName
      userTel
      updatedTime
      createdTime
      registerYear
      mileage
      paymentMethod
      bookingCompletedTime
    }
}
`;

export const getBookings = `
query getBookings($workShopId: String!, $date1: String!, $date2: String!) {
  getBookings(workShopId: $workShopId, date1: $date1, date2: $date2) {
    batteryType
    batteryReplacementDate
    chassisNumber
    passWithConditions
    colourCode
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    insuranceCompany
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    progress
    bookingStdHrsMin
    images
    qcImages
    assigneeName
    assigneeId
    workShopId
    invoiceId
    updatedTime
    createdTime
    comment
    registerYear
    mileage
    paymentMethod
    bookingCompletedTime
    totalPrice
    total
  }
}
`;

export const getJobs = `
query getJobs($workShopId: String!, $bookingId: String!) {
  getJobs(workShopId: $workShopId, bookingId: $bookingId) {
    assigneeId
    assigneeName
    bookingId
    description
    plateNumber
    price
    qtyHours
    qtyNo
    rate
    sortKey
    status
    comment
    updatedTime
    createdTime
    qcComment
    repairItem
  }
}
`;

export const updateJobStatus = `
mutation updateJobStatus($bookingId: String!, $sortKey: String!, $status: String!, $updatedTime: AWSDateTime!) {
  updateJobStatus(bookingId: $bookingId, sortKey: $sortKey, status: $status, updatedTime: $updatedTime) {
    plateNumber
    assigneeName
    description
    qtyHours
    rate
    price
    bookingId
    sortKey
    status
    updatedTime
  }
}
`;

export const searchEmployee = `
  query searchEmployee($workShopId: String!, $searching: String!) {
    searchEmployee(workShopId: $workShopId, searching: $searching) {
      name
      role
      nic
      employeeId
      userName
      status
      dob
      contactNumber
    }
  }
`;

export const updateJobAssignee = `
mutation updateJobAssignee($bookingId: String!, $sortKey: String!, $assigneeId: String!, $assigneeName: String!, $updatedTime: AWSDateTime!) {
  updateJobAssignee(bookingId: $bookingId, sortKey: $sortKey, assigneeId: $assigneeId, assigneeName: $assigneeName, updatedTime: $updatedTime) {
    plateNumber
    assigneeId
    assigneeName
    bookingId
    description
    price
    qtyHours
    rate
    sortKey
    updatedTime
  }
}
`;

export const updateComment = `
mutation updateComment($bookingId: String!, $sortKey: String!, $comment: String!, $updatedTime: AWSDateTime!) {
  updateComment(bookingId: $bookingId, sortKey: $sortKey, comment: $comment, updatedTime: $updatedTime) {
    plateNumber
    assigneeId
    bookingId
    description
    price
    qtyHours
    comment
    rate
    sortKey    
    updatedTime
    createdTime
  }
}
`;

export const updateQcComment = `
mutation updateQcComment($bookingId: String!, $sortKey: String!, $qcComment: String!, $updatedTime: AWSDateTime!) {
  updateQcComment(bookingId: $bookingId, sortKey: $sortKey, qcComment: $qcComment, updatedTime: $updatedTime) {
    plateNumber
    assigneeId
    bookingId
    description
    price
    qtyHours
    rate
    sortKey
    updatedTime
  }
}
`;

export const addJobs = `
mutation addJobs($checkInJobs: [inputJobs]) {
  addJobs(checkInJobs: $checkInJobs) {
    description
    plateNumber
    price
    qtyHours
    rate
    status
    updatedTime
    createdTime
    repairItem
  }
}
`;

export const getRepairItem = `
query getRepairItem($workShopId: String!, $labourRateType: String!) {
  getRepairItem(workShopId: $workShopId, labourRateType: $labourRateType) {
    description
    checkList
    assistanceAllocation
    category
    assistantTechGroup2
    applicableVehicleModel
    applicableVehicleMake
    activeStatus
    assistantTechIncentive
    imageLink
    incomeAccountName
    instructionsNotes
    labourRate
    labourRateType
    mainTechGroup1
    mainTechGroup2
    mainTechIncentive
    partNumber
    pl
    price
    searching
    sortKey
    stdHrsMin
    tax1Vat
    tax3
    videoLink
    workShopId
  }
}
`;

export const getBookingsunauth = `
query getBookings($workShopId: String!) {
  getBookings(workShopId: $workShopId) {
    selectedDate
    selectedTime
    sortKey
    workShopId
    plateNumber
    ownerName
    status
    progress
    searching
    bookingStdHrsMin
    comment
  }
}
`;

export const getServiceEstimation = `
query getServiceEstimation($workShopId: String!, $applicableVehicleMake: String, $applicableVehicleModel: String) {
  getServiceEstimation(workShopId: $workShopId, applicableVehicleMake: $applicableVehicleMake, applicableVehicleModel: $applicableVehicleModel) {
    applicableVehicleModel
    assistantTechGroup2
    assistanceAllocation
    assistantTechIncentive
    category
    checkList
    description
    imageLink
    applicableVehicleMake
    activeStatus
    incomeAccountName
    instructionsNotes
    labourRate
    labourRateType
    mainTechGroup1
    mainTechGroup2
    mainTechIncentive
    partNumber
    pl
    price
    searching
    sortKey
    stdHrsMin
    tax1Vat
    tax3
    videoLink
    workShopId
  }
}
`;

export const updateBookingData = `
mutation updateBooking($updatedBooking: BookingInput) {
  updateBooking(updatedBooking: $updatedBooking) {
    assigneeName
    assigneeId
    batteryType
    chassisNumber
    colourCode
    comment
    engineNumber
    fuelType
    images
    qcImages
    insuranceNumber
    insuranceRenevalDate
    jobType
    licenseNumber
    totalPrice
    total
    licenseRenevalDate
    manufactureYear
    make
    model
    ownerEmail
    ownerName
    ownerTel
    plateNumber
    progress
    searching
    selectedDate
    selectedTime
    workShopId
    passWithConditions
    userTel
    userName
    userEmail
    tireSize
    status
    sortKey
    invoiceId
    updatedTime
    createdTime
    registerYear
    mileage
    paymentMethod
    bookingCompletedTime
  }
}
`;

export const updateSubscription = `
subscription updateSubscription {
  onBookingApproveReject {
    batteryType
    chassisNumber
    colourCode
    comment
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    totalPrice
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    images
    bookingStdHrsMin
    workShopId
    updatedTime
    createdTime
    assigneeName
    assigneeId
    registerYear
    mileage
    investigationCompletedTime
  }
}
`;

export const addBookingParts = `
mutation addBookingParts($newBookingParts: [BookingPartsInput]) {
  addBookingParts(newBookingParts: $newBookingParts) {
    bookingId
    description
    image
    partId
    partNumber
    price
    quantity
    workShopId
  }
}
`;

export const getBookingParts = `
query getBookingParts($bookingId: String!) {
  getBookingParts(bookingId: $bookingId) {
    partNumber
    price
    quantity
    workShopId
    partId
    image
    description
    bookingId
  }
}
`;
