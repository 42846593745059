import {
  Button,
  Checkbox,
  Col, message, Modal, Popconfirm, Popover,
  Row, Table, Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/lib/upload';
import { ulid } from 'ulid';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { USER_ROLES } from '../../../../../enum/User';
import { JOB_STATUS } from '../../../../../enum/JobStatus';
import { TaskBoardItemStatus } from '../../../../../enum/TaskboardTypes';
import {
  addBookingInvoice,
  updateBooking, updateJob, updateJobComment, uploadBookingContent,
} from '../../actions';
import { getTotalPrice } from '../common';
import QCColumn from '../columns/QCColumn';
import { getFormattedJobs } from '../../../../../utils/tableUtil';
import { S3_METHODS } from '../../../../../enum/ConfigurationData';
import { hasRole } from '../../../../../utils/commonUtil';

const getBase64 = (file: RcFile): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

type QcSummeryType = {
  isCommentsRequired: boolean,
  isAllQCPass: boolean,
};

const initialQCSSummery = {
  isCommentsRequired: false,
  isAllQCPass: false,
};

type QCType = {
  userRoles: any[],
  jobList: any[],
  getAllBookings: Function,
  item: any,
  workShopId: string,
  getJobs: Function,
  t: Function,
  configData: any,
  setJobList: Function,
};

const QC = ({
  userRoles,
  jobList,
  getAllBookings,
  item,
  workShopId,
  getJobs,
  t,
  configData,
  setJobList,
}: QCType) => {
  const [isPassWithCondition, setPassWithCondition] = useState<boolean>(false);
  const [qcSummery, setQCSummery] = useState<QcSummeryType>(initialQCSSummery);
  const [formattedJobList, setFormattedJobList] = useState<any[]>([]);
  const { currency = '', languageCode = '' }: any = configData?.currency;
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [value, setValue] = useState<number>(0);

  const dataColumn = QCColumn(t, userRoles, getJobs);

  const createInvoice = async (booking: any) => {
    message.destroy();
    message.loading('Creating invoice').then();
    const amount = getTotalPrice(jobList);
    const invoice = {
      workShopId: booking.workShopId,
      clientEmail: booking.ownerEmail || '',
      clientMobile: booking.ownerTel || '',
      clientName: booking.ownerName || '',
      plateNumber: booking.plateNumber || '',
      bookingId: booking.sortKey,
      status: TaskBoardItemStatus.PENDING_PAYMENT,
      amount,
    };
    const res: any = await addBookingInvoice(invoice);
    message.destroy();
    message.success('Invoice created successfully').then();
    return res?.data?.addInvoice;
  };

  const updateBookingQC = async (updatedBooking: any) => {
    message.destroy();
    message.loading('Updating the booking').then();
    updateBooking(updatedBooking).then(() => {
      if (updatedBooking.status !== TaskBoardItemStatus.TO_DO) {
        message.destroy();
        message.success('Job Completed').then();
      } else {
        message.destroy();
        message.success('Job rework').then();
      }
      getAllBookings();
    }).catch(() => {
      message.destroy();
      message.error('Something went wrong when updating the booking').then();
    });
  };

  const getQCSummery = () => {
    let pendingPaymentCount: number = 0;
    let rejectedJobCount: number = 0;
    let commentAddedFailedCount: number = 0;
    let totalCount: number = 0;

    jobList.forEach((job: any) => {
      if (job.status === JOB_STATUS.REJECTED) {
        rejectedJobCount += 1;
      } else if (job.status === JOB_STATUS.PENDING_PAYMENT) {
        pendingPaymentCount += 1;
      } else if (job.status === JOB_STATUS.QC && (job.qcComment && job.qcComment !== '')) {
        commentAddedFailedCount += 1;
      }
    });

    totalCount = pendingPaymentCount + rejectedJobCount + commentAddedFailedCount;
    const isCommentsRequired = totalCount !== jobList.length;
    const isAllQCPass = (pendingPaymentCount + rejectedJobCount) === jobList.length;
    if (isAllQCPass) {
      setPassWithCondition(false);
    }
    setQCSummery({ isCommentsRequired, isAllQCPass });
  };
  const uploadImages = async () => {
    message.loading('Uploading Images..').then();
    const promises = fileList.map(async (file: any) => {
      const extension = file.name.split('.').pop();
      const fileName = `${ulid()}.${extension}`;
      const key = `${workShopId}/${item.sortKey}/QC/${fileName}`;
      const contentType = file.type;
      const { body } = await uploadBookingContent(key, S3_METHODS.put, contentType);
      if (body?.url) {
        await fetch(body.url, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType,
          },
          body: file.originFileObj,
        });
        return fileName;
      }
      throw new Error('Something went wrong');
    });
    const images = await Promise.all(promises);
    message.success('QC attachment uploaded successfully!').then();
    return images;
  };

  const submitQC = async () => {
    const totalPrice = getTotalPrice(jobList);
    const qcImages = await uploadImages();
    const updatedBooking = {
      ...item,
      workShopId,
      status: TaskBoardItemStatus.PENDING_PAYMENT,
      updatedTime: new Date(),
      totalPrice,
      qcImages,
    };
    if (isPassWithCondition) {
      updatedBooking.passWithConditions = isPassWithCondition;
    } else if (qcSummery.isAllQCPass) {
      const { invoiceId }: any = await createInvoice(item);
      updatedBooking.invoiceId = invoiceId;
    } else {
      message.destroy();
      message.warning('Please click on pass with condition of investigate all jobs').then();
    }
    await updateBookingQC(updatedBooking);
    return null;
  };

  const onImageChange = (info) => {
    const { fileList: newFileList, file } = info;
    setFileList(newFileList);
    if (file.status === 'done') {
      message.destroy();
      message.success('Updated successfully');
      setValue(value + 1);
    }
  };

  const handlePreview = async (file: any) => {
    const data = file;
    if (!file.url && !file.preview) {
      data.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onImageRemove = async (image) => {
    const imgArray = item.qcImages || [];
    setValue(value - 1);
    if (imgArray.includes(image.name)) {
      const tempArray: any = [];
      fileList.map((img: any) => {
        if (img.name !== image.name) {
          tempArray.push(img.name);
        }
        return null;
      });
    }
    message.success('Image deleted successfully');
  };

  const onPassWithConditionChange = (e: boolean) => {
    setPassWithCondition(e);
  };

  const getPopoverContent = () => {
    let popMessage: string = 'Click to Continue';
    if (value === 0 && !qcSummery.isAllQCPass) {
      popMessage = '';
    }
    if (value === 0) {
      popMessage = 'Please upload job finalized images';
    }
    if (qcSummery.isAllQCPass) {
      if (!qcSummery.isAllQCPass && !isPassWithCondition) {
        popMessage = 'Please select the reason';
      } else if (qcSummery.isCommentsRequired) {
        popMessage = 'Add comments to missing fields to continue';
      }
    } else if (qcSummery.isCommentsRequired) {
      popMessage = 'Please select the reason';
    }
    return popMessage;
  };

  const backToQueue = async () => {
    const updatedBooking = {
      ...item,
      status: TaskBoardItemStatus.TO_DO,
    };
    await updateBookingQC(updatedBooking);
    let jobs = jobList.map(async (job: any) => {
      const updatedJob = job;
      if (updatedJob.status === TaskBoardItemStatus.QC) {
        updatedJob.status = TaskBoardItemStatus.TO_DO;
        await updateJobComment(job.bookingId, job.sortKey, '');
      }
      if (updatedJob.sortKey === job.sortKey) {
        await updateJob(updatedJob.bookingId, updatedJob.sortKey, updatedJob.status);
      }
      return { ...job };
    });
    jobs = await Promise.all(jobs);
    setJobList(jobs);
    getAllBookings();
  };

  useEffect(() => {
    if (jobList && jobList.length) {
      getQCSummery();
      setFormattedJobList(getFormattedJobs(jobList, currency, languageCode));
    }
  }, [jobList]);

  return (
    <>
      <Row>
        <h4>{t('Job finalized images')}</h4>
      </Row>
      <Row>
        <ImgCrop grid aspect={1280 / 720} quality={0.4}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onImageChange}
            onPreview={handlePreview}
            customRequest={dummyRequest}
            disabled={hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)}
            onRemove={onImageRemove}
            accept=".png, .jpg, .jpeg"
            maxCount={5}
          >
            {fileList.length < 5 && '+ Upload'}
          </Upload>
        </ImgCrop>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
          width={800}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Row>
      <Row>
        <header className="tableTopic" />
      </Row>
      <Row className="tab-row">
        <Col span={24}>
          <Table
            columns={dataColumn}
            dataSource={formattedJobList}
            pagination={false}
          />
        </Col>
      </Row>
      <Row style={{ float: 'right', marginTop: '1rem' }}>
        <Col style={{ paddingTop: '5px', marginRight: '8px' }}>
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => onPassWithConditionChange(e.target.checked)}
            checked={isPassWithCondition}
            disabled={
          userRoles.includes(USER_ROLES.RECEPTIONIST)
              || userRoles.includes(USER_ROLES.TECHNICIAN)
              || qcSummery.isAllQCPass
        }
          >
            {t('Passed with conditions')}
          </Checkbox>
        </Col>
        <Col>
          <Popover placement="topLeft" content={getPopoverContent()} trigger="hover">
            {qcSummery.isAllQCPass || isPassWithCondition ? (
              <Button
                htmlType="submit"
                style={{
                  borderRadius: '8px',
                  marginRight: 20,
                  background: '#131a54',
                  color: 'white',
                  marginTop: 25,
                }}
                disabled={
              userRoles.includes(USER_ROLES.RECEPTIONIST)
              || userRoles.includes(USER_ROLES.TECHNICIAN)
              || (!qcSummery.isAllQCPass && !isPassWithCondition)
              || qcSummery.isCommentsRequired
              || value === 0
        }
                onClick={submitQC}
              >
                { t('Submit to Pending Payment') }
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to move this job card to Jobs in Queue?"
                onConfirm={backToQueue}
                okText="Yes"
                cancelText="No"
                disabled={
                  userRoles.includes(USER_ROLES.RECEPTIONIST)
                  || userRoles.includes(USER_ROLES.TECHNICIAN) || qcSummery.isCommentsRequired
                }
              >
                <Button
                  style={{
                    borderRadius: '8px',
                    marginRight: 20,
                    background: '#131a54',
                    color: 'white',
                    marginTop: 25,
                  }}
                  disabled={
                userRoles.includes(USER_ROLES.RECEPTIONIST)
                || userRoles.includes(USER_ROLES.TECHNICIAN) || qcSummery.isCommentsRequired
              }

                >
                  { t('Back to Job In Queue') }
                </Button>
              </Popconfirm>
            )}

          </Popover>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
    workshop: state.userData.workshop,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(QC);
